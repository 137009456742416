<template>
   <svg v-if="locale!=='ge'" width="433" height="52" viewBox="0 0 433 52" fill="none" xmlns="http://www.w3.org/2000/svg">
     <g clip-path="url(#clip0_2_2640)">
       <path
           d="M182.185 30.1662V52.0001H214.62V30.1662H226.025V0.223145H201.904V17.7795H196.091V0.223145H170.779V30.1662H182.185Z"
           fill="white"
       />
       <path d="M125.779 17.6982V51.9796H153.032V17.6982H164.967V0.202637H113.854V17.6982H125.779Z" fill="white"/>
       <path d="M51.1036 17.6984V0.223145H0V52.0001H51.1036V34.5249H23.0106V17.6984H51.1036Z" fill="white"/>
       <path
           d="M430.46 49.2023H433.001V2.57466H430.46V0H428.189V46.2628L376.145 0H373.123V2.57466H370.582V49.2023H373.123V51.777H375.394V5.84873L427.068 51.777H430.46V49.2023Z"
           fill="white"
       />
       <path
           d="M231.668 46.8912V49.2023H234.229V51.777H294.197V46.8912H239.572L294.197 4.87563V0H234.219V2.57466H231.668V4.87563H286.213L231.668 46.8912Z"
           fill="white"
       />
       <path
           d="M94.4031 34.5249V17.6984H108.029V0.223145H56.9258V17.6984H71.4025V34.5249H56.9258V52.0001H108.029V34.5249H94.4031Z"
           fill="white"
       />
       <path
           d="M363.638 4.87563V0H303.55V2.54425H301.029V49.2328H303.55V51.777H363.638V46.8912H305.852V28.3314H363.638V23.4456H305.852V4.89591L363.638 4.87563Z"
           fill="white"
       />
     </g>
     <defs>
       <clipPath id="clip0_2_2640">
         <rect width="433" height="52" fill="white"/>
       </clipPath>
     </defs>
   </svg>
   <svg v-else width="490" height="52" viewBox="0 0 490 52" fill="none" xmlns="http://www.w3.org/2000/svg">
     <g clip-path="url(#clip0_135_8667)">
       <path d="M61.082 0V52H81.738V15.4326L95.2451 15.4221V52H115.901V0H61.082Z" fill="white"/>
       <path d="M54.8295 52V20.9779H34.1631V36.536L20.656 36.5465V0H0V52H54.8295Z" fill="white"/>
       <path d="M217.727 0V52H238.383V15.4326L251.9 15.4221V52H272.556V0H217.727Z" fill="white"/>
       <path d="M122.344 0V52H177.152V15.464H190.649V52H211.295V0H122.344ZM156.496 36.9545H143V15.464H156.496V36.9545Z"
             fill="white"/>
       <path
           d="M422.64 0.418518H367.363V5.40926H420.226V47.1244L367.363 47.1453V52L422.64 51.9895V49.4262H425.2V2.9819H422.64V0.418518Z"
           fill="white"/>
       <path
           d="M487.452 52V49.4262H490.012V27.4857H487.452V24.9537H436.389V5.29417L490.012 5.27325V0.418518H431.426V49.4366H433.986V52.0105H487.462L487.452 52ZM436.389 29.9549H485.038V47.0093H436.389V29.9549Z"
           fill="white"/>
       <path
           d="M359.195 23.2274V20.6535H335.199V0.418518H281.337V2.9819H278.777V23.0809H281.337V25.6547H303.585V51.9895H359.195V49.4157H361.755V23.2274H359.195ZM303.585 20.6535H283.751V5.40926H330.225V20.6535H303.585ZM356.781 46.9883H308.559V25.6547H356.781V46.9988V46.9883Z"
           fill="white"/>
     </g>
     <defs>
       <clipPath id="clip0_135_8667">
         <rect width="490" height="52" fill="white"/>
       </clipPath>
     </defs>
   </svg>
 </template>
 
 
 <script setup>
 import {computed} from "vue";
 import {useRoute} from "vue-router";
 import {useMenuStore} from "@/store/pinia/menu";
 
 let store = useMenuStore()
 
 let locale = computed(() => store.getLang);
 </script>