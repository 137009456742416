<template>
  <div class="smart-components-wrapper" :key="$route.path">
    <StickyButton v-if="contentStatus === 'done' && selected_menu?.configs?.length && selected_menu?.configs.includes('show_sticky_button')"/>
    <transition-group name="list" v-on:before-enter="beforeEnter" v-on:enter="enter" v-on:leave="leave" tag="div">
      <div v-for="(component, componentKey) in loopComponents" :key="componentKey" class="list-item"
        :class="{ zIndex: component.selectedComponent === 'ProjectTabs' }" v-show="toggleShowComponent(component)">
        <component :is="component.selectedComponent" :contentData="getContentData(component)"
          v-if="contentStatus === 'done'" />
      </div>
    </transition-group> 
    <Footer />
  </div>
</template> 

<script>
import { defineComponent, defineAsyncComponent, nextTick, computed, watch, onMounted, ref } from 'vue';
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
import { mapState } from 'pinia';
import { useMenuStore } from '../../store/pinia/menu/index';
import { handleResize } from '@/composables/resizeHandler';
import clearTimeoutHandler from '@/composables/clearTimeoutHandler';
import Velocity from 'velocity-animate';
import Footer from '@/components/layout/Footer.vue';

// need to delete
import MainTitle from '@/components/smartComponents/MainTitle/MainTitle.vue';
import HouseFinder from '@/components/smartComponents/HouseFinder/HouseFinder.vue';
import ApartmentsMap from '@/components/smartComponents/ApartmentsPlane/ApartmentsPlane.vue';
import ContactForm from '@/components/smartComponents/ContactForm/ContactForm.vue';
import ProjectTabs from '@/components/smartComponents/ProjectTabs/ProjectTabs.vue';

import StickyButton from '@/components/smartComponents/StickyButton/StickyButton.vue';
import ContactBanner from '@/components/smartComponents/ContactBanner/ContactBanner.vue';

const MainArticles = () => import('@/components/smartComponents/MainArticles/MainArticles.vue');
const MainIntro = () => import('@/components/smartComponents/MainIntro/MainIntro.vue');
const MainSlider = () => import('@/components/smartComponents/MainSlider/MainSlider.vue');
const VideoBox = () => import('@/components/smartComponents/VideoBox/VideoBox.vue');
// need to delete

export default defineComponent({
  // need to delete
  components: {
    MainTitle,
    HouseFinder,
    ApartmentsMap,
    ContactForm,
    ProjectTabs,
    VideoBox,
    MainSlider,
    MainIntro,
    MainArticles,
    Footer,
    StickyButton,
    ContactBanner
  },
  name: 'smartComponentsLoader',
  props: {},
  data() {
    return {
      top: false
    };
  },
  computed: {
    ...mapState(useMenuStore, ['current_content_request_status', 'indx', 'selected_menu', 'current_content', 'cache']),
    contentStatus() {
      return this.current_content_request_status;
    },
    smartComponentsState() {
      return this.indx.smartComponents;
    },
    loopComponents() {
      let smartComponents = this.$get(this.selected_menu, 'secondary_data');

      let singleOrList =
        this.$get(this.selected_menu, 'routeParams.singleview') || this.$get(this.selected_menu, 'routeParams.apview')
          ? 'single'
          : 'list';

      let componentTitles = [];
      let ret = [];
      for (let i in smartComponents) {
        if (this.$get(smartComponents[i], 'enabled') != 1) continue;
        if (parseInt(this.$get(smartComponents[i], 'childComponent')) === 1) continue;

        //// exclude component depend on menu settings
        if (singleOrList === 'list' && this.$get(smartComponents[i], 'listLayout') != 1) {
          continue;
        } else if (singleOrList === 'single' && this.$get(smartComponents[i], 'singleLayout') != 1) {
          continue;
        }

        ret.push(smartComponents[i]);
        componentTitles.push(smartComponents[i].selectedComponent);
      }
      return ret;
    },
  },
  methods: {
    getContentData(component) {
      let selectedComponent = this.$get(component, 'selectedComponent');
      let componentUnicId = this.$get(component, 'unicId');
      let primary = this.$get(component, 'primary');
      let res = {};
      // @Delete in if (primary) checking
      if (primary && this.$get(this.current_content, ['primary', 'data', 'listCount'])) {
        res = this.$get(this.current_content, ['primary']);
      }

      if (!this.$get(res, 'conf.enabled')) {
        res.conf = this.$get(this.current_content, ['secondary', selectedComponent + '_' + componentUnicId, 'conf']);
      }
      if (!this.$get(res, 'data.listCount')) {
        res.data = this.$get(this.current_content, ['secondary', selectedComponent + '_' + componentUnicId, 'data']);
      }

      return res;
    },
    toggleShowComponent(component) {
      let conf = _.get(component, 'conf');

      // console.log(this.cache.showHiddenComponent, conf, component, component.unicId); //, _.get(component, 'unicId')
      return !(_.includes(conf, 'hidden') && (!this.cache.showHiddenComponent || !_.includes(conf, this.cache.showHiddenComponent)));


    },
    beforeEnter: function (el) {
      el.style.opacity = 0;
      el.style.transform = `translateY(50px)`;
      el.style.position = 'absolute';
    },
    enter: function (el, done) {
      let delay = 500;
      setTimeout(function () {
        Velocity(
          el,
          {
            opacity: 1,
            translateY: '0'
          },
          {
            duration: 600,
            easing: "swing",
            complete: done
          }
        );
        el.style.opacity = 1;
        el.style.transform = `translateY(0)`;
        el.style.position = 'relative';
      }, delay);
    },
    leave: function (el, done) {
      let delay = 100;
      setTimeout(function () {
        Velocity(
          el,
          { opacity: 0, },
          { complete: done }
        );
      }, delay);
    }

  },
  watch: {
    contentStatus: {
      immediate: true,
      deep: true,
      handler(ns) {
        if (ns === 'done') {
          let components = this.$get(this.indx, 'smartComponents');

          for (let i in components) {
            this.$options.components[i] = defineAsyncComponent({
              loader: () => import('../../components/smartComponents/' + i + '/' + i),
              // loadingComponent: `<template><div>Loading</div></template>`,
            });
          }
        }
      },
    },
  },

  setup() {
    // STORE
    const store = useMenuStore();

    // COMPOSABLES
    const { windowWidth } = handleResize();

    // COMPUTED
    const currentContentStatus = computed(() => store.current_content_request_status);
    const isMainSliderLoaded = computed(() => store.isMainSliderLoaded);

    // REFS
    const transitioningStepOneTO = ref(null);
    const transitioningStepTwoTO = ref(null);

    // store.isDefaultPageTransitioningStepOne = true;

    watch(
      [currentContentStatus, isMainSliderLoaded],
      async (ns) => {
        if (!store.selected_menu) return;
        store.isPageTransitioningStepTwo = false;
        store.isPageTransitioningStepOne = true;


        if (ns[0] === 'done' && store.isPageTransitioningStepOne && ns[1]) {
          document.querySelector('body').classList.add('modal-open');
          clearTimeoutHandler(transitioningStepOneTO.value);
          clearTimeoutHandler(transitioningStepTwoTO.value);
          transitioningStepOneTO.value = window.setTimeout(() => {
            store.isPageTransitioningStepOne = false;
            store.isPageTransitioningStepTwo = true;
          }, 1000);
          transitioningStepTwoTO.value = window.setTimeout(() => {
            store.isPageTransitioningStepTwo = false;
            // store.disableScrollStatus = false;
            document.querySelector('body').classList.remove('modal-open');
          }, 2000);
        }
      },
      { immediate: true }
    );

    // METHODS
    const pageTransitionHandlerStepOne = async () => {
      await nextTick();

      return new Promise((resolve) => {
        store.isPageTransitioningStepOne = true;
        store.disableScrollStatus = true;

        setTimeout(() => {
          store.scrollToTopLoco = !store.scrollToTopLoco;
          store.disableScrollStatus = false;
          resolve();
        }, 500);
      });
    };


    // const defaultPageTransitionHandlerStepOne = () => {

    //   store.disableScrollStatus = true;

    //   setTimeout(() => {
    //     store.isDefaultPageTransitioningStepOne = false;
    //     store.isPageTransitioningStepTwo = true;
    //   }, 1700);
    //   setTimeout(() => {
    //     store.disableScrollStatus = false;
    //     store.isPageTransitioningStepTwo = false;
    //   }, 2800);
    // }

    // ROUTE GUARDS
    onBeforeRouteUpdate(async (to, from) => {
      if (Object.entries(from.query).length
        && to.fullPath.includes('projects')
        && !to.fullPath.includes('apartment')) return true;

      await pageTransitionHandlerStepOne();

      return true;
    });
    onBeforeRouteLeave(async (to, from) => {
      if (Object.entries(from.query).length
        && to.fullPath.includes('projects')
        && !to.fullPath.includes('apartment')) return true;
      await pageTransitionHandlerStepOne();
      store.scrollToTopLoco = !store.scrollToTopLoco;

      return true;
    });


    return {
      store,
    };
  },
});
</script>

<style lang="scss">
.smart-components-wrapper {
  overflow: hidden;
  min-height: 100vh;

  .list-item {
    transition-timing-function: cubic-bezier(.29, 0, .1, .99);

    &.zIndex {
      z-index: 99;
    }
  }

  @include mq(mobile-sm) {
    padding-top: 27px;
  }
}

</style>
