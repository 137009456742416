<template>
  <div @click="toggleDropDown" class="select-container">
    <span class="active-item" :class="{ active: showDrop }">
      {{ activeItem }}
      <svg v-if="filterOptions.length > 1" width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M24.1948 2.85017L13.551 13.5256C13.4243 13.6523 13.2871 13.7418 13.1392 13.7942C12.9914 13.8474 12.833 13.874 12.6641 13.874C12.4951 13.874 12.3367 13.8474 12.1889 13.7942C12.0411 13.7418 11.9038 13.6523 11.7771 13.5256L1.10169 2.85017C0.806032 2.55451 0.658203 2.18494 0.658203 1.74145C0.658203 1.29797 0.816591 0.917833 1.13337 0.601056C1.45015 0.284279 1.81972 0.12589 2.24209 0.12589C2.66446 0.12589 3.03403 0.284279 3.35081 0.601056L12.6641 9.91431L21.9773 0.601055C22.273 0.305396 22.6371 0.157566 23.0696 0.157566C23.5029 0.157566 23.878 0.315955 24.1948 0.632732C24.5115 0.94951 24.6699 1.31908 24.6699 1.74145C24.6699 2.16382 24.5115 2.5334 24.1948 2.85017Z"
          fill="black" />
      </svg>

    </span>
    <div v-if="filterOptions.length > 1" :class="{ active: showDrop }" class="drop-down">
      <svg width="24" height="25" class="close-icon" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M12.2071 12.9142L23 23.7071L23.7071 23L12.9142 12.2071L23.7071 1.41421L23 0.707107L12.2071 11.5L0.707107 0L0 0.707107L11.5 12.2071L0 23.7071L0.707107 24.4142L12.2071 12.9142Z"
          fill="black" />
      </svg>
      <img src="../../assets/imgs/subtract.png" class="image" alt="">
      <div v-for="item in filterOptions" @click="change(item)" class="option-item">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";

let props = defineProps({
  options: {
    type: Array,
    default: []
  },
  event: {
    type: Function,
  }
})
let activeItem = ref('')
let showDrop = ref(false)
const change = (item) => {
  activeItem.value = item.title

  if (props.event) props.event(item)
}
const toggleDropDown = () => {
  showDrop.value = !showDrop.value
}
let filterOptions = computed(() => props.options?.filter(item => item.title !== activeItem.value))
onMounted(() => {
  activeItem.value = props.options?.find(item => item.active)?.title
})

</script>

<style lang="scss">
.select-container {
  position: relative;

  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 3;
    cursor: pointer;
  }

  .active-item {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 54px;
    color: #000000;
    text-transform: uppercase;
    
    svg{
      margin-left: 10px;
      transition: .6s;
    }

    &.active svg{
      transform: rotate(180deg);
    }
    @media only screen and (max-width: 1023px) {
      font-size: 36px;
      margin-left: 13px;
    }
  }

  .drop-down {
    position: absolute;
    opacity: 0;
    bottom: 0;
    padding: 50px 0 40px;
    left: 50%;
    transform: translateX(-50%) translateY(calc(100% + 20px));
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: transparent;
    width: 230px;
    font-family: HelveticaRoman;
    z-index: 12;

    .option-item {
      font-weight: 400;
      font-size: 28px;
      line-height: 34px;
      color: #000000;
      opacity: 0.2;
      transition: all .45s ease;
      margin-bottom: 30px;
      text-transform: uppercase;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        opacity: .9;
      }
    }

    img {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      height: 100%;
    }

    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

@media screen and (max-width: 1900px){ 
  .select-container{
    .active-item{
      font-size: 34px;
    }
  }
}
@media screen and (max-width: 1439px) { 
  .select-container{
    .active-item{
      font-size: 21px;
    }
  }
}
</style>