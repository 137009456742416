import {defineStore} from "pinia";

export const useGallery = defineStore("gallery", {
    state: () => ({
        _animated: false, _galleryItems: null,
    }), getters: {
        galleryItems(state) {
            return state._galleryItems;
        }, opened(state) {
            return state._opened;
        },

    }, actions: {
        setGalleryItems(value) {
            this._galleryItems = value;
        }, setOpened(value) {
            this._opened = value;
        },
    },
});
