<template>
  <div class="infrastructure ">
    <div v-for="(item, index) in infrastructure" class="infrastructure__gallery flex">
      <div class="infrastructure__gallery-info flex column">
        <div class="title">{{ item.title }}</div>
        <div class="desc" v-html="item.teaser"></div>
        <div class="arrows">
          <span v-html="arrowLeft" class="mr-21 pointer" :class="'swiper-button-prev'+item.id"></span>
          <span v-html="arrowRight" class="pointer" :class="'swiper-button-next'+item.id"></span>
        </div>
      </div>
      <div class="infrastructure__gallery-swiper" @mouseleave="hideZoomIcon">
        <div class="zoom" :class="{ hide: selectedIndex !== index || hideZoom }"
             :style="selectedIndex === index && zoomPositions">
          <span v-html="galleryZoom"></span>
        </div>
        <Swiper
            :slides-per-view="swiperOptions?.slidesPerView"
            :space-between="swiperOptions?.spaceBetween"
            :breakpoints="swiperOptions?.breakpoints"
            :modules="modules"
            :speed="800"
            :free-mode="true"
            :navigation="{
            nextEl: `.infrastructure .swiper-button-next${item.id}`,
            prevEl: `.infrastructure .swiper-button-prev${item.id}`,
          }"
        >
          <SwiperSlide v-for="(image, index) in item.image"
                       @mouseenter="store.getComponentCursor($event, 'scale')"
                       @mouseleave="store.getComponentCursor($event, null)"
                       :key="index">
            <!--            <ImageScale-->
            <!--                :item="{ image: image.url }"-->
            <!--            />-->
            <img :src="image.url"
                 @click="showGallery( item.image)"
                 alt="">
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {Navigation} from 'swiper';
import {arrowLeft, arrowRight, galleryZoom} from '@/components/UI/svgs.js';
import {useMenuStore} from "@/store/pinia/menu";
import ImageScale from "@/components/Popups/ImageScale.vue";
import {useProjects} from "@/store/pinia/projects";
import {useGallery} from "@/store/pinia/gallery";

const modules = [Navigation];
let store = useMenuStore()
let galleryStore = useGallery()
let props = defineProps({
  contentData: {
    type: Object,
  }
})
let projectStore = useProjects()
const selectedIndex = ref(null);
const zoomPositions = ref({
  top: 0,
  left: 0,
});
const swiperOptions = {
  slidesPerView: 1.2,
  spaceBetween: 20,
  speed: 800,
  breakpoints: {
    1440: {
      slidesPerView: 1.2,
      spaceBetween: 20,
    },
  },
};
const hideZoom = ref(false);

const moveMouse = (event, indx) => {
  const top = event.offsetY + 'px';
  const left = event.offsetX + 'px';
  zoomPositions.value = {top, left};
  selectedIndex.value = indx;
};
const hideZoomIcon = () => {
  hideZoom.value = true;
  selectedIndex.value = null;
};

let infrastructure = computed(() => props.contentData?.data?.list?.map(item => ({
  ...item,
  image: item.image?.map(img => ({
    ...img,
    url: img?.devices?.desktop
  }))
}))?.sort((a, b) => {
  if (a.title?.toLowerCase() === projectStore.projectActiveTab?.toLowerCase()) return -1;
  if (b.title?.toLowerCase() === projectStore.projectActiveTab?.toLowerCase()) return 1;
  return 0;
}))

const showGallery = (items) => {
  galleryStore.setGalleryItems(items)
}
</script>

<style lang="scss">
.infrastructure {
  padding-left: 21px;
  @include mq(mobile) {
    padding-left: 16px;
  }

  &__gallery {
    height: 781px;
    padding: 70px 0 70px 120px;
    border-bottom: 1px solid $black;
    position: relative;

    @include mq(desktop-sm) {
      height: 585px;
      padding: 55px 0 55px 100px;
    }
    @include mq(tablet) {
      height: 520px;
      padding: 45px 0 45px 120px;
    }
    @include mq(tablet-sm) {
      height: 312px;
      padding: 30px 0 30px 80px;
    }
    @include mq(mobile) {
      height: auto;
      flex-direction: column;
      border-bottom: unset !important;
      border-top: unset !important;
      padding: 0 0 30px 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .swiper {
      height: 100%;
      @include mq(mobile) {
        overflow: visible;
      }

      .swiper-wrapper {
        transition-timing-function: cubic-bezier(.14, 0, .27, 1) !important;
      }

      .swiper-slide {
        height: 100%;

        .image-speed {
          height: 100%;

          .hovered-image-container {
            height: 100%;
          }
        }
      }
    }

    .title {
      font-size: 64px;
      line-height: 81px;
      @include mq(desktop-sm) {
        font-size: 48px;
        line-height: 72px;
      }
      @include mq(tablet) {
        font-size: 40px;
        line-height: 60px;
      }
      @include mq(tablet-sm) {
        font-size: 24px;
        line-height: 36px;
      }
      @include mq(mobile) {
        padding-bottom: 235px;
        font-size: 32px;
        line-height: 48px;
      }
    }

    .desc {
      font-size: 34px;
      line-height: 41px;
      @include mq(desktop-sm) {
        font-size: 24px;
        line-height: 29px;
      }
      @include mq(tablet) {
        font-size: 21px;
        line-height: 25px;
      }
      @include mq(tablet-sm) {
        font-size: 12px;
        line-height: 14px;
      }
      @include mq(mobile) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    &-info {
      width: 45%;
      margin-right: 89px;
      justify-content: space-between;
      height: 100%;
      padding-bottom: 110px;
      position: relative;

      .arrows {
        position: absolute;
        bottom: 0;
        left: 0;

        svg {
          @include mq(desktop-sm) {
            width: 16px;
            height: 16px;
          }
          @include mq(mobile) {
            width: 21px;
            height: 21px;
          }
        }
      }

      @include mq(tablet-sm) {
        padding-bottom: 40px;
        margin-right: 30px;
      }
      @include mq(mobile) {
        width: 100%;
        padding-bottom: 50px;
      }
    }

    &-swiper {
      width: 100%;
      overflow: hidden;
      position: relative;

      .absolute-layer {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 9;
        cursor: none;
        @include mq(tablet-sm) {
          display: none;
        }
      }

      @include mq(mobile) {
        height: 200px;
        margin-top: 55px;
        position: absolute;
        overflow: visible;
      }
    }

    .zoom {
      position: absolute;
      z-index: 10;
      cursor: none;
      opacity: 1;
      visibility: visible;
      transition: opacity 0.2s ease;
      @include mq(tablet-sm) {
        display: none;
      }

      &.hide {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
</style>
