<template>
  <div class="blocks-render">
    <div class="content">
      <h2 class="header-title">{{ $fn.tr('Choose Floor') }}</h2>
      <div class="blocks-list">
        <div v-for="item in blocks" @click="changeBlock(item.slug)" :class="{ active: item.slug === BLockFromRoute }"
             class="item">
          <svg width="25" height="41" viewBox="0 0 25 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 0V31.5545L8.92857 41V9.50566L18.9078 3.64469H4.46429V32.8002H0V0.000244069L25 0Z"
                  fill="white"/>
          </svg>
          <span class="title">{{ item.title }}</span>
        </div>
      </div>
    </div>
    <div class="render-container">
      <div class="render-img" id="blockRender" v-html="renderImg"></div>
      <teleport to="body">
        <transition name="pop">
          <!-- blockPopUp -->
          <div v-if="blockPopUp" :style="{ ...blockPopUp.positions }" class="block-pop-up">
            <img class="background" src="../../../assets/imgs/subtract.png" alt="">
            <div class="content">
              <div class="up">
                <div class="pin-top">
                  <div class="item">
                    <span class="lg-ttl">{{ $fn.tr('Floor:') }}</span> <span class="num">{{
                      blockPopUp.data.relatedData === 0 ? 'L' : blockPopUp.data.relatedData
                    }}</span>
                  </div>
                  <div class="item">
                    <span class="tll">{{ $fn.tr('Available apartments:') }}</span> <span
                      class="num">{{ showFreeFlatsNumber() }}</span>
                  </div>
                </div>
                <span v-if="blockPopUp.sold" class="sold">{{ $fn.tr('sold') }}</span>
              </div>

            </div>
          </div>
        </transition>
      </teleport>
    </div>
    <div :style="{ top: scrollTop + 'px' }" @click="backRoute" class="back-container">
      <svg width="52" height="54" viewBox="0 0 52 54" class="back" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.539 24.5L51.7109 24.5L51.7109 29.5L10.0425 29.5L30.7787 50.2362L27.2431 53.7717L2.48746 29.0161L0.71969 27.2483L2.48746 25.4805L27.2431 0.724831L30.7787 4.26036L10.539 24.5Z"
              fill="black"/>
      </svg>
    </div>
  </div>
</template>

<script setup>
import { useProjects } from "@/store/pinia/projects";
import { computed, nextTick, onMounted } from "vue";
import { ref, watch } from "vue";
import { useMenuStore } from "@/store/pinia/menu";
import { useRoute, useRouter } from "vue-router";
import { floor } from "lodash";
import func from "../../../helpers/func";

let router = useRouter();
let route = useRoute();
const store = useMenuStore();
const blockPopUp = ref(null);
let menuStore = useMenuStore();
let projectStore = useProjects();
const avaliavleFlatsByFloor = ref([]);
let BLockFromRoute = computed(() => route?.params?.path4?.split('-')?.[1]);
let renderImg = computed(() => {
  let block = projectStore._blocks?.find(item => item.slug?.toLowerCase() === BLockFromRoute.value?.toLowerCase());

  return block?.render?.[0]?.svgLayer;
});
const polygons = computed(() => {
  let poly = projectStore._blocks?.find(item => item.slug?.toLowerCase() === BLockFromRoute.value?.toLowerCase());

  return poly?.render?.[0]?.polygons;
});
let currentLang = computed(() => menuStore.getLang);
const blocks = computed(() => projectStore._blocks?.map(item => ({
  ...item,
  title: item.title?.toUpperCase()
}))?.filter(item => !item.conf?.includes('sold'))?.sort((a, b) => {
  return (a.slug > b.slug) ? 1 : ((b.slug > a.slug) ? -1 : 0);
}));
let activeBlock = computed(() => blocks.value?.find(item => item.slug === BLockFromRoute.value));

const hoverPolygon = () => {
  let polygonsDom = document.querySelectorAll('#blockRender polygon');

  polygonsDom.forEach(item => {
    item.addEventListener('mouseover', async (e) => {
      let polygonId = Number(item.getAttribute("polygonid"));
      let polygonInfoId = 0;
      for (const [key, value] of Object.entries(polygons.value)) {
        if (value.ui === polygonId) polygonInfoId = key;
      }
      let polygonInfo = polygons.value?.[polygonInfoId];
      let itemPosition = item.getBoundingClientRect();
      let floor = projectStore._floors.find(floor => {
        return floor?.floor_number === polygonInfo.relatedData && floor.block_id === activeBlock.value?.id;
      });
      blockPopUp.value = {
        data: polygonInfo || {},
        title: polygonInfo?.data?.[currentLang.value]?.title,
        positions: {
          left: e.clientX < window.innerWidth / 2 ? e.clientX / 2 + itemPosition.width / 2 + 180
              + 'px' : e.clientX - itemPosition.width / 2 - 180 + 'px',
          top: itemPosition.y < 250 ? itemPosition.y + 100 + 'px' : itemPosition.y - 150 + 'px'
        },
        sold: floor?.conf?.includes('sold')
      };
    });
    item.addEventListener('mouseleave', () => {
      blockPopUp.value = null;
    });
    item.addEventListener('click', () => {
      let polygonId = Number(item.getAttribute("polygonid"));

      let polygonInfoId = 0;
      for (const [key, value] of Object.entries(polygons.value)) {
        if (value.ui === polygonId) polygonInfoId = key;
      }
      let polygonInfo = polygons.value?.[polygonInfoId];

      let floor = projectStore._floors.find(floor => {
        return floor?.floor_number === polygonInfo.relatedData && floor.block_id === activeBlock.value?.id;
      });

      if (!floor || floor?.conf?.includes('sold')) return;

      let path = route.path.substr(route.path.length - 1) === '/' ? route.path.slice(0, -1) : route.path;
      router.push({
        path: `${ path }/floor-${ polygonInfo.relatedData }/`,
        query: route.query
      });
    });

  });
};
const changeBlock = (block) => {
  let routePath = route.path?.split('/');
  let blockIndex = routePath?.findIndex(item => item?.includes('block'));
  routePath[blockIndex] = `block-${ block }`;

  router.push({
    path: `${ routePath.join('/') }`,
    query: route.query
  });
};
let scrollTop = ref(0);
const setScrollTop = () => {
  let img = document.querySelector('.render-img')?.getBoundingClientRect()?.height;
  let back = document.querySelector('.back-container')?.getBoundingClientRect()?.height;

  let max = img - back - 100;
  let min = max / 2 - 100;
  scrollTop.value = min;
};
const scrollEvent = () => {
  setTimeout(() => {
    let img = document.querySelector('.render-img')?.getBoundingClientRect()?.height;
    let back = document.querySelector('.back-container')?.getBoundingClientRect()?.height;

    let max = img - back - 100;
    let min = max / 2 - 100;

    store.Loco?.on('scroll', func => {
      scrollTop.value = func.scroll.y < min ? min : func.scroll.y > max ? max : func.scroll.y;

    });
  }, 100);
};
const backRoute = () => {
  let routePath = route.path?.split('/');
  let blockIndex = routePath?.findIndex(item => item?.includes('apview'));
  routePath = routePath.splice(0, blockIndex);

  router.push({
    path: `${ routePath.join('/') }`,
    query: route.query
  });
};
const showFreeFlatsNumber = () => {
  return avaliavleFlatsByFloor.value?.[Number(blockPopUp.value.data.relatedData)]?.length || 0;
};
onMounted(async () => {
  scrollEvent();
  await nextTick();
  setScrollTop();
});

watch(() => activeBlock.value, (next) => {

  if (next?.id) {
    projectStore.getAvaliableFlatsByFloor(next?.id)
        .then((res) => {
          avaliavleFlatsByFloor.value = res;
        });
  }
}, { immediate: true });
watch(() => activeBlock.value, async (next) => {
  await nextTick();
  hoverPolygon();
}, { immediate: true });
</script>

<style lang="scss" scoped>
.blocks-render:deep {
  color: white;
  position: relative;

  .content {
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 144px;

    @media only screen and (max-width: 1440px) and (min-width: 1280px) {
      padding-top: 134px;
    }

    @media only screen and (max-width: 1279px) and (min-width: 768px) {
      padding-top: 75px;
    }

    .header-title {
      text-align: center;
      font-weight: 400;
      font-size: 64px;

      @media only screen and (max-width: 1650px) and (min-width: 1280px) {
        font-size: 48px;
      }

      @media only screen and (max-width: 1279px) and (min-width: 768px) {
        font-size: 40px;
      }
    }

    .blocks-list {
      display: flex;
      align-items: center;
      margin: 0 auto;
      width: fit-content;
      margin-top: 55px;

      @media only screen and (max-width: 1440px) {
        margin-top: 34px;
      }

      .item {
        display: flex;
        align-items: center;
        text-align: center;
        margin-right: 34px;
        cursor: pointer;
        opacity: .5;
        transition: all .45s ease;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          opacity: 1;
        }

        .title {
          font-weight: 400;
          font-size: 54px;
          color: #FFFFFF;
          margin-left: 10px;
          line-height: unset;

          @media only screen and (max-width: 1650px) and (min-width: 1280px) {
            font-size: 40px;
          }

          @media only screen and (max-width: 1279px) and (min-width: 768px) {
            font-size: 34px;
          }
        }
      }


    }
  }

  .back-container {
    width: 120px;
    height: 120px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(100px);

    @media only screen and (max-width: 1650px) and (min-width: 1280px) {
      width: 100px;
      height: 100px;

      .back {
        width: 25px;
      }
    }

    @media only screen and (max-width: 1279px) and (min-width: 768px) {
      width: 50px;
      height: 50px;

      .back {
        width: 15px;
      }
    }
  }

  .render-img {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    svg {
      polygon {
        opacity: 0;
        mix-blend-mode: overlay;
        fill: white;
        cursor: pointer;
        transition: all .45s ease-out;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

}

.pin-top {
  .item {
    display: flex;
    flex-direction: column;
  }

  .item:nth-child(2) {
    margin-top: 40px;
  }

  .num {
    font-size: 24px;
    margin-top: 5px;
    font-weight: 400;
  }

  .lg-ttl {
    font-size: 34px;
    font-weight: bold;
  }

  .ttl {
    font-size: 16px;
  }
}
</style>

<style lang="scss">
.pop-enter-active,
.pop-leave-active {
  transition: all 0.5s ease-out;
}

.pop-enter-from,
.pop-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.block-pop-up {
  position: absolute;
  background: transparent;
  z-index: 99;
  min-width: 230px;
  padding: 33px 29px;
  transition: all .45s ease-out;

  .background {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .content {
    z-index: 1;
    position: relative;
  }

  .up {
    .description {
      font-size: 34px;
      display: block;
      font-weight: bold;
      color: black;
      margin-top: 0;
    }

    .sold {
      font-size: 18px;
    }
  }

  .title {
    display: block;
    font-size: 60px;
    line-height: unset;
    font-family: HelveticaThin, sans-serif;

    &:nth-child(2) {
      font-size: 34px;
    }
  }

}
</style>