/* eslint-disable */

import {createApp, h, App as AppInterface} from 'vue';

import 'element-plus/dist/index.css';
import App from './App.vue';
import {createPinia} from 'pinia';
import {get, size, includes} from 'lodash';
import dynamicTitle from './helpers/dynamic_title';
import fn from './helpers/func';
import axios from 'axios';
import {useCookies} from 'vue3-cookies';
import VueSocialSharing from 'vue-social-sharing';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import router from './router';
import VueGoogleMaps from '@fawmi/vue-google-maps'

import 'swiper/swiper.min.css';
import './assets/styles/main.scss';

import {createHead} from '@vueuse/head';
import {useMenuStore} from './store/pinia/menu/index';
import {mapActions, mapState} from 'pinia';
import {set_menu_full_urls} from './store/pinia/menu/menu_helpers';
import vClickOutside from "click-outside-vue3"


const head = createHead();
declare module 'vue' {
    interface ComponentCustomProperties {
        $get: typeof get;
        $size: typeof size;
        $includes: typeof includes;
        $fn: typeof fn;
    }
}
const lodashMethods = {
    install(app: AppInterface) {
        app.config.globalProperties.$get = get;
        app.config.globalProperties.$size = size;
        app.config.globalProperties.$fn = fn;
    },
};

const app: AppInterface = createApp({
    mounted: async function () {
      setTimeout(()=>{
          /// if set lang identifier into url set it as site current lang
          if (this.$route.name && size(this.$route.name) >= 2 && this.$route.name.slice(-3, -2) === '_') {
              this.locale = this.$route.name.slice(-2);
          }
          /// set ajax default request lang
          axios.defaults.headers.common['lang'] = this.locale;

          if (this.$route.query.preview) this.$cookies.set('siteMode', this.$route.query.preview, '1d');
          /// load site main data
          const store = useMenuStore();
          store.fetchIndx();
      },1)
    },

    watch: {
        '$route.name': {
            immediate: true,
            handler() {
                // return false;
                /// set selected locale
                /// set axios default header for selected language
                /// send main request to server

                if (!this.$route.name) return false;
                const store = useMenuStore();

                if (this.$route.name && size(this.$route.name) == 2) {
                    store.locale = this.$route.name;
                }
                /// set ajax default request lang
                axios.defaults.headers.common['lang'] = this.locale;
                set_menu_full_urls(store);

                /// load indx part after every language change
                this.indxTranslatable();
                this.getTranslations();
            },
        },
    },
    computed: {
        ...mapState(useMenuStore, ['locale']),
    },
    methods: {
        ...mapActions(useMenuStore, ['fetchIndx', 'indxTranslatable', 'getTranslations']),
    },
    render() {
        return h(App);
    },
});

app.directive('click-outside', {
    beforeMount: function (el, binding) {
        // Define ourClickEventHandler
        const ourClickEventHandler = (event: any) => {
            if (!el.contains(event.target) && el !== event.target && binding.value) {
                // as we are attaching an click event listern to the document (below)
                // ensure the events target is outside the element or a child of it
                binding.value(event); // before binding it
            }
        };
        // attached the handler to the element so we can remove it later easily
        el.__vueClickEventHandler__ = ourClickEventHandler;

        // attaching ourClickEventHandler to a listener on the document here
        document.addEventListener('click', ourClickEventHandler);
    },
    unmounted: function (el) {
        // Remove Event Listener
        document.removeEventListener('click', el.__vueClickEventHandler__);
    },
});

app.use(lodashMethods);

app.use(dynamicTitle);
app.use(router);
app.use(useCookies);
app.use(head);
app.use(createPinia());
app.use(VueSocialSharing);
app.use(ElementPlus);
app.use(vClickOutside)

// ALLIANCE GOOGLE MAP API KEY - AIzaSyBzSQEoWh18PfcrspjSg04aDg6AtUqMPSE

const fetchApiKey = async () => {
    let store = useMenuStore()

    let tmp = await fn.postData('main/getapikey');
    store.api_key = tmp.data.website_google_maps_key
};
fetchApiKey();

app.mount('#app');
