<template>
   <div class="flat-item">
      <div class="top">
         <div class="content">
            <div class="num">
               <span>{{ $fn.tr('Apartment') }}</span><span> {{ data.flat_number }}</span>
            </div>
            <div class="description">
               <p>{{ data.type.title_2 }}</p>
               <p v-for="view in data.viewType" :key="view.id"> {{ view.title }}</p>
            </div>
         </div>
         <div class="img-box">
            <img :src="data.image" class="image" alt="">
         </div>
      </div>
      <div class="bottom">
         <p class="item">{{currency === 'USD' ? '$' : '₾' }} {{ price }}</p>
         <p class="item">{{ data.development_types_area_m2 }} {{ $fn.tr('M') }}<sup>2</sup></p>
      </div>
   </div>
</template>

<script setup>
import { computed } from 'vue';
import { useMenuStore } from "@/store/pinia/menu";

const props = defineProps({
   data: {
      type: Object,
      required: true
   },
   currency: {
      type: String,
      default: 'USD'
   }
})

const menustore = useMenuStore();
const usd = Number( menustore.indx?.usd?.usd);

const currency = computed(() => {
   return props.currency
})

const price = computed(() => {
   return currency.value === "USD" ? moneyFormat(Math.floor(props.data.price)) : moneyFormat(Math.floor(props.data.price * usd))
})

function moneyFormat(n) {
  return (Math.round(n * 100) / 100).toLocaleString();
}

</script>

<style lang="scss" scoped>
.flat-item {
   padding: 12px;
   background: #F1F1F1;
   transition: .6s;
   height: 160px;
   display: flex;
   flex-direction: column;
}

.flat-item.active {
   -webkit-box-shadow: inset 0px 0px 0px 4px rgba(192, 134, 145, 1);
   -moz-box-shadow: inset 0px 0px 0px 4px rgba(192, 134, 145, 1);
   box-shadow: inset 0px 0px 0px 4px rgba(192, 134, 145, 1);
}

.swiper-slide-active .flat-item {
   -webkit-box-shadow: inset 0px 0px 0px 4px rgba(192, 134, 145, 1);
   -moz-box-shadow: inset 0px 0px 0px 4px rgba(192, 134, 145, 1);
   box-shadow: inset 0px 0px 0px 4px rgba(192, 134, 145, 1);
}

.top {
   display: flex;
   align-items: flex-start;
   padding-bottom: 21px;
}

.content {
   flex: 1;
   padding-right: 10px;
}

.num {
   display: flex;

   span {
      font-size: 16px;
   }

   span:last-child {
      display: block;
      margin-left: 5px;
   }
}

.description {
   display: flex;
   flex-direction: column;
   margin-top: 8px;

   p {
      margin-bottom: 4px;
      font-size: 12px;
   }

   p:last-child {
      margin-bottom: 0;
   }
}

.img-box {
   width: 80px;

   img {
      max-width: 100%;
      height: auto;
      object-fit: cover;
   }
}

.bottom {
   display: flex;
   align-items: center;
   padding-top: 12px;
   margin-top: auto;
   border-top: 1px solid rgba(0, 0, 0, 0.05);

   .item {
      padding: 4px 0;
      font-size: 16px;
      margin-right: 12px;
      padding-right: 12px;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
   }

   .item:last-child {
      padding-right: 0;
      margin-right: 0;
      border-right: none;
   }
}
</style>