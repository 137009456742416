<template>
  <Teleport to="#sticky_button">
    <router-link v-if="boxScrollOffset && data.link" :to="'/' + store.indx.locale + data.link.url"
      class="sticky-project-btn" :style="{'top': boxScrollOffset + 'px'}">
      <svg class="btn" width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M28.8562 19.5475L0.941265 19.5475V16.5475L29.1875 16.5475L15.045 2.40497L17.1663 0.283652L33.7038 16.8212L34.7645 17.8818L33.7038 18.9425L17.1663 35.48L15.045 33.3587L28.8562 19.5475Z"
          fill="white" />
      </svg>

      <svg class="border" width="208" height="147" viewBox="0 0 208 147" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.5957 48.3379L1.5957 145.829L206.029 145.829L206.029 10.9674L182.021 10.9674L182.021 1.57421L67.3682 1.57422L67.3682 48.3379L1.5957 48.3379Z"
          stroke="white" stroke-width="2" />
      </svg>

      <div v-if="data?.title" class="txt">
        {{ data.title }}
      </div>
    </router-link>
  </Teleport>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useMenuStore } from '@/store/pinia/menu';
import { get } from "lodash";

const store = useMenuStore();
const boxScrollOffset = ref(false);
const data = computed(() => {
  return get(store, 'indx.widgets.explore-cityzen');
});

onMounted(() => {
  //  calculate sticky box scroll offset value
  let default_layout_padding_top = 0;
  let box_height = 0;
  if (window.innerWidth > 1600) {
    default_layout_padding_top = 102;
    box_height = 170;
  } else {
    default_layout_padding_top = 75;
    box_height = 80;
  }
  const position_from_bottom = 35;
  let extraPadding = window.innerWidth > 1900 ? 330 : window.innerWidth > 1600 ? 300 : 200;
  boxScrollOffset.value = Math.floor((window.innerHeight / 2) - box_height + extraPadding);
});
</script>

<style lang="scss" scoped>
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.sticky-project-btn {
  cursor: pointer;
  position: fixed;
  width: 180px;
  height: 150px;
  background: #857EAB;
  z-index: 999;
  right: 35px;
  opacity: 0;
  animation: fadeIn 1s 2s forwards;

  .border {
    position: absolute;
    top: -1px;
    left: 19px;
    right: 13px;
    bottom: 13px;
    width: 150px;
  }

  .btn {
    position: absolute;
    top: 13px;
    left: 20px;
  }

  .txt {
    position: absolute;
    bottom: 33px;
    left: 45px;
    right: 45px;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 21px;
    color: #fff;
    font-weight: 700;
  }

  .btn {
    display: block;
    transition: .4s;
  }

  &:hover {
    .btn {
      transform: translateX(5px);
    }
  }
}

@media screen and (max-width: 1679px) {

  .sticky-project-btn {
    width: 110px;
    height: 80px;

    .border {
      display: block;
      position: absolute;
      top: 5px;
      left: 5px;
      bottom: unset;
      right: unset;
      width: 100px;
      height: auto;
    }
  }

  .sticky-project-btn .btn {
    height: 15px;
    top: 6px;
    left: 3px;
    display: block;
  }

  .sticky-project-btn .txt {
    text-align: center;
    bottom: 12px;
    left: 15px;
    right: 15px;
    padding: 0;
    font-size: 14px;
  }
}

@media screen and (max-width: 1023px) {
  .sticky-project-btn {
    display: none;
  }
}
</style>