<template>
  <div class="flats-render-list">
    <div class="head">
      <div class="block-lists">
        <button class="back item" @click="onBack">
          <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M2.70499 9.41382L18.1413 9.41382L18.1413 8.41382L2.87734 8.41382L10.5743 0.716873L9.86719 0.0097659L1.23051 8.64645L0.876952 9L1.23051 9.35355L9.86719 17.9902L10.5743 17.2831L2.70499 9.41382Z"
              fill="white" />
          </svg>
        </button>
        <div class="item" v-for="item in blocks" :key="item.key"
          :class="{ 'active': route.path.includes(`block-${item.slug}`) }" @click="onBlockClick(item)">
          {{ item.title }}
        </div>
      </div>
      <svg class="arrow" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.86816 5.74023L0.102488 0.860839L9.63384 0.86084L4.86816 5.74023Z" fill="black" />
      </svg>
    </div>

    <div class="change-floor">
      <span class="ttl">{{ $fn.tr('Floor') }}</span>
      <div class="changer">
        <div class="next" :class="{ 'disable': Number(floorNumber) === 0 }" @click="onChangeFloor('prev')">
          <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="21.9521" y="0.870605" width="14.8334" height="1.05953" transform="rotate(135 21.9521 0.870605)"
              fill="black" />
            <rect x="1.31152" y="0.121338" width="14.8334" height="1.05953" transform="rotate(45 1.31152 0.121338)"
              fill="black" />
          </svg>
        </div>
        <div class="current">
          <span>{{ floorNumber }}</span>
        </div>
        <div class="prev" :class="{ 'disable': Number(floorNumber) === activeFloors.length - 1 }"
          @click="onChangeFloor('next')">
          <svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.952148" y="10.6099" width="14.8334" height="1.05953" transform="rotate(-45 0.952148 10.6099)"
              fill="black" />
            <rect x="21.5928" y="11.3591" width="14.8334" height="1.05953" transform="rotate(-135 21.5928 11.3591)"
              fill="black" />
          </svg>
        </div>
      </div>
    </div>

    <div class="render-img" id="floorRender" v-html="renderImg"></div>
    <teleport to="body">
      <div class="render-img render-img-fixed" id="floorRender" v-html="renderImg"></div>
    </teleport>
    <div class="sub-head">
      <div class="ttl">
        <span>{{ flats.length }}</span>
        <span> {{ $fn.tr('Apartments') }}</span>
      </div>
      <div class="price-toggle" :class="{ 'gel': activeCurrency === 'GEL' }">
        <div class="circle"></div>
        <div class="item" :class="{ 'active': activeCurrency === 'USD' }" @click="activeCurrency = 'USD'">
          <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.87705 13.5919H-0.000259399C0.117532 15.1379 0.610786 16.3526 1.4795 17.2361C2.34822 18.1048 3.51877 18.6128 4.99117 18.76V20.5711H6.2059V18.76C6.91265 18.7011 7.5605 18.5539 8.14946 18.3183C8.73842 18.0827 9.2464 17.7661 9.6734 17.3686C10.1004 16.9563 10.4317 16.4704 10.6673 15.9109C10.9176 15.3514 11.0427 14.7109 11.0427 13.9894C11.0427 13.3121 10.9102 12.7305 10.6452 12.2446C10.3949 11.7587 10.0857 11.3538 9.71757 11.0299C9.34947 10.706 8.96664 10.4557 8.5691 10.279C8.17155 10.0876 7.82554 9.95506 7.53106 9.88144L6.2059 9.52807V4.09492C7.5605 4.34523 8.38505 5.19922 8.67953 6.65689H10.5568C10.2624 5.36118 9.76174 4.34523 9.05499 3.60903C8.34824 2.87283 7.39854 2.43111 6.2059 2.28387V0.804108H4.99117V2.26178C4.35804 2.30595 3.76172 2.44583 3.20221 2.68142C2.65742 2.917 2.17889 3.24093 1.76662 3.6532C1.36907 4.05075 1.05251 4.52928 0.816921 5.08879C0.581338 5.63357 0.463546 6.23726 0.463546 6.89984C0.463546 7.54769 0.55189 8.09984 0.728578 8.55628C0.905265 9.01273 1.17766 9.40291 1.54576 9.72684C1.92858 10.036 2.39975 10.3084 2.95926 10.544C3.5335 10.7649 4.2108 10.971 4.99117 11.1624V17.0152C4.10773 16.868 3.37153 16.5072 2.78257 15.933C2.20834 15.3588 1.9065 14.5784 1.87705 13.5919ZM6.2059 17.0152V11.4716C6.60345 11.5894 6.97891 11.7219 7.33228 11.8692C7.68566 12.0017 7.9875 12.171 8.23781 12.3772C8.48811 12.5833 8.68689 12.8336 8.83413 13.1281C8.98137 13.4078 9.05499 13.7612 9.05499 14.1882C9.05499 14.6594 8.98137 15.0716 8.83413 15.425C8.70161 15.7637 8.50284 16.0508 8.23781 16.2864C7.9875 16.5072 7.68566 16.6766 7.33228 16.7944C6.99363 16.9121 6.61817 16.9858 6.2059 17.0152ZM4.99117 4.02866V9.19678C4.62307 9.09371 4.28442 8.97592 3.97521 8.8434C3.66601 8.71089 3.39362 8.55628 3.15803 8.3796C2.93717 8.18819 2.76049 7.95996 2.62797 7.69493C2.51018 7.41518 2.45128 7.08389 2.45128 6.70106C2.45128 5.8618 2.69423 5.22866 3.18012 4.80167C3.68074 4.35995 4.28442 4.10228 4.99117 4.02866Z"
              fill="#000" />
          </svg>
        </div>
        <div class="item" :class="{ 'active': activeCurrency === 'GEL' }" @click="activeCurrency = 'GEL'">
          <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.35907 17.0469V15.6573L3.55205 15.4401V15.375C2.45194 14.7091 1.63409 13.8262 1.09852 12.7261C0.577412 11.6115 0.31686 10.3811 0.31686 9.0349C0.31686 7.28341 0.722163 5.79248 1.53277 4.56209C2.34337 3.31724 3.5231 2.48492 5.07193 2.06514V0.328125H6.46154V1.8263C6.75105 1.79735 7.04779 1.78287 7.35176 1.78287C7.65574 1.78287 7.95248 1.79735 8.24198 1.8263V0.328125H9.63159V2.08685C11.1225 2.5211 12.2805 3.38237 13.1056 4.67066C13.9307 5.95894 14.3432 7.56568 14.3432 9.49087H12.324C12.324 8.08678 12.1068 6.88535 11.6726 5.88657C11.2528 4.88778 10.5725 4.1785 9.63159 3.75873V9.46916H8.24198V3.3679C7.95248 3.32447 7.65574 3.30276 7.35176 3.30276C7.04779 3.30276 6.75105 3.32447 6.46154 3.3679V9.46916H5.07193V3.73701C4.14553 4.15679 3.45796 4.83712 3.00923 5.778C2.5605 6.71889 2.33614 7.85518 2.33614 9.18689C2.33614 10.4028 2.55326 11.4812 2.98752 12.4221C3.42177 13.3485 4.0442 14.0795 4.85481 14.6151C5.66541 15.1362 6.63524 15.3967 7.7643 15.3967H13.3662V17.0469H1.35907Z"
              fill="#000" />
          </svg>
        </div>
      </div>
    </div>
    <transition name="fade" appear>
      <div class="flat-list-wrapper" id="flat-list-wrapper">
        <flat-item v-for="item in flats" :key="item.id" :data="item" :currency="activeCurrency" class="x-flat"
          @click="goInFlat(item)" />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";

import MSelect from '@/components/UI/MSelect'
import { computed, onMounted, watch, ref, onUnmounted } from "vue";
import fn from "@/helpers/func";
import { useProjects } from "@/store/pinia/projects";
import { useRoute, useRouter } from "vue-router";
import FlatItem from './FlatItem.vue'
import { useMenuStore } from "@/store/pinia/menu";



// global hooks
let projectStore = useProjects()
let route = useRoute()
let router = useRouter()
let menuStore = useMenuStore()

//header section   

const activeCurrency = ref('USD');


//computed
let BLockFromRoute = computed(() => route?.params?.path4?.split('-')?.[1])
let FloorFromRoute = computed(() => route?.params?.path5?.split('-')?.[1])
let currentLang = computed(() => menuStore.getLang)
let renderImg = computed(() => {
  let floor = activeFloors.value?.find(item => item.floor_number === Number(FloorFromRoute.value))

  return floor?.render?.[0]?.svgLayer
})
let blockNumber = computed(() => route.params?.path4?.split('-')?.[1])
let floorNumber = computed(() => route.params?.path5?.split('-')?.[1])
let floors = computed(() => projectStore._floors?.map(item => {
  return {
    ...item,
    active: item.floor_number === Number(floorNumber.value),
    title: `${fn.tr('floor')} ${item.title}`
  }
})?.sort((a, b) => a.floor_number - b.floor_number))
let activeBlock = computed(() => blocks.value.find(item => item.slug === BLockFromRoute.value))
let activeFloors = computed(() => floors.value?.filter(item => item.block_id === activeBlock.value?.id)
  ?.sort((a, b) => a.floor_number - b.floor_number))
let blocks = computed(() => projectStore._blocks?.filter(item => !item.conf?.includes('sold'))
  ?.map(item => {
    return {
      ...item,
      active: item.slug === blockNumber.value,
      title: `${item.title}`
    }
  }))
let flats = computed(() => projectStore._flats?.filter(item => !item?.conf?.includes('sold') && !item?.conf?.includes('reserved'))
  ?.map(item => ({
    ...item,
    price: Math.floor(item.price_m2 * item.development_types_area_m2),
    image: item?.type?.image2d?.[0]?.devices?.desktop
  })))
const all_flats = computed(() => {
  return projectStore._flats;
})
//methods
const changeFloor = (item) => {
  let routePath = route.path?.split('/')
  let floorIndex = routePath?.findIndex(item => item?.includes('floor'))

  routePath[floorIndex] = !item.floor_number && item.floor_number !== 0 ? 'floor-' : `floor-${item.floor_number}`
  router.push({
    path: `${routePath.join('/')}`,
    query: route.query
  })

}
const changeBlock = (item) => {
  let routePath = route.path?.split('/')
  let floorIndex = routePath?.findIndex(item => item?.includes('block'))
  routePath[floorIndex] = !item.slug ? 'block-' : `block-${item.slug}`

  router.push({
    path: `${routePath.join('/')}`,
    query: route.query
  })
}

//flats section
const goInFlat = (item) => {
  let path = route.path.substr(route.path.length - 1) === '/' ? route.path.slice(0, -1) : route.path

  router.push({
    path: `${path}/${item.id}-apartment`,
  })
}
//computed



function onScroll() {
  const appDiv = document.getElementById('app');
  const floorRenderDiv = document.querySelector('.render-img-fixed');
  const flatsWrapper = document.querySelector('#flat-list-wrapper');
  const scrollTop = appDiv.scrollTop;

  if (scrollTop >= 195) {
    floorRenderDiv.classList.add('active');
  } else {
    floorRenderDiv.classList.remove('active');
  }

  if((floorRenderDiv.getBoundingClientRect().bottom  + 160) > flatsWrapper.getBoundingClientRect().bottom){
    floorRenderDiv.classList.remove('active');
  }

  const flats = document.querySelectorAll('.x-flat');

  

  for (const [index, flat] of flats.entries()) {
    if (flat.getBoundingClientRect().top > 275) {
      flat.classList.add('active');
      findActivePolygon(index);
      flats?.forEach((item) => {
        if (item !== flat && item.classList.contains('active')) {
          item.classList.remove('active');
        }
      })
      break;
    }
  }
}

onMounted(() => {
  const appDiv = document.getElementById('app');
  const flats = document.querySelectorAll('.x-flat');
  flats[0].classList.add('active');
 
  if (appDiv) {
    appDiv.addEventListener('scroll', onScroll);
  }
  setTimeout(() => {
    projectStore.getFlats(1, false, 500);
  }, 200)
})
onUnmounted(() => {
  // const appDiv = document.getElementById('app');
  // if (appDiv) {
  //   appDiv.addEventListener('scroll', checkScroll);
  // }
});

const mySwiper = ref(null);

const onReachEnd = () => {
  const appDiv = document.getElementById('app');
  appDiv.style.overflow = 'auto';
}

const findActivePolygon = (index) => {
  let floor = activeFloors.value?.find(item => item.floor_number === Number(FloorFromRoute.value));
  let id;
  const flat_number = flats.value[index]?.flat_number;

  if (floor?.render?.[0].polygons && Object.values(floor?.render?.[0].polygons).length && flat_number) {
    id = Object.values(floor.render?.[0].polygons).find((item) => item.relatedData === flats.value[index].flat_number)?.ui || false;
  }
  const polygons = document.querySelectorAll('polygon');
  const polygon = document.querySelectorAll(`polygon[polygonid='${id}']`);
  if (!polygon) return false;

  for (let i in polygons) {
    if (polygons[i] && polygons[i].nodeType === Node.ELEMENT_NODE) {
      polygons[i].style.opacity = 0;
    }
  }

  if (polygon?.length > 1) {
    polygon.forEach((item) => {
      item.style.fill = 'rgba(159, 170, 123, 0.5)';
      item.style.opacity = 1;
    })
  } else if (polygon?.length) {
    polygon.style.fill = 'rgba(159, 170, 123, 0.5)';
    polygon.style.opacity = 1;
  }

}

const setPolygonsStatusClasses = (flats) => {
  if (!flats?.length) return false;
  let floor = activeFloors.value?.find(item => item.floor_number === Number(FloorFromRoute.value));
  let id;
  flats.forEach((flat) => {
    id = Object.values(floor.render?.[0].polygons).find((item) => item.relatedData === flat.flat_number)?.ui || false;
    if (!id) return false;
    const polygon = document.querySelectorAll(`polygon[polygonid='${id}']`)
    if (polygon?.length > 1) {
      polygon.forEach((item) => {
        if (flat?.conf?.includes('reserved')) {
          item.classList.add('reserved')
        } else if (flat?.conf?.includes('sold')) {
          item.classList.add('sold')
        }
      })
    } else if (polygon?.length) {
      if (flat?.conf?.includes('reserved')) {
        polygon.classList.add('reserved')
      } else if (flat?.conf?.includes('sold')) {
        polygon.classList.add('sold')
      }
    }
  })
}

const onBlockClick = (block) => {
  if (route.path?.includes('/apview/block')) return;
  if (block?.conf?.includes('sold')) return;
  let path = route.path.substr(route.path.length - 1) === '/' ? route.path.slice(0, -1) : route.path;

  router.push({
    path: `${path}/apview/block-${block.slug}/`,
    query: route.query
  });
}

const onBack = () => {
  router.push({ path: '/' + currentLang.value + fn.tr('/projects/cityzen-central-park/') });
}

const onChangeFloor = (value) => {
  let item;
  if (value === 'next') {
    item = activeFloors.value[Number(floorNumber.value) + 1] || false;
  } else {
    item = activeFloors.value[Number(floorNumber.value) - 1] || false;
  }
  if (!item) return false;

  let routePath = route.path?.split('/')
  let floorIndex = routePath?.findIndex(item => item?.includes('floor'))

  routePath[floorIndex] = !item.floor_number && item.floor_number !== 0 ? 'floor-' : `floor-${item.floor_number}`
  router.push({
    path: `${routePath.join('/')}`,
    query: route.query
  })

}

watch(() => flats.value, (next) => {
  findActivePolygon(0);
  const flats = document.querySelectorAll('.x-flat');
  if(flats?.length){
    flats[0].classList.add('active');
  }
}, { immediate: true })

watch(() => all_flats.value, (next) => {
  setPolygonsStatusClasses(next);
}, { immediate: true })

</script>

<style lang="scss" scoped>
.sub-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  .ttl {
    display: flex;
    font-size: 16px;
    text-transform: uppercase;
  }

  .ttl span:last-child {
    display: block;
    font-weight: bold;
    margin-left: 4px;
  }
}

.price-toggle {
  position: relative;
  display: flex;
  padding: 8px;
  background: #F4F4F4;
  border-radius: 50px;

  svg path {
    transition: 0.6s;
  }

  .item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    transition: .6s;
  }

  .circle {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    background: #000;
    transition: .3s;
  }

  &.gel .circle {
    transform: translateX(100%);
  }

  .item.active svg path {
    fill: #fff;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 2s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.swiper-container {
  padding: 0 16px;
  padding-top: 30px;
}

.swiper {
  height: 510px !important;
}

.swiper-slide {
  height: auto !important;
}

.head {
  position: relative;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;

  .arrow {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.block-lists {
  display: flex;
  align-items: center;
  justify-content: center;

  .item {
    font-size: 21px;
    color: #686868;
    line-height: 25px;
    margin-left: 21px;
    cursor: pointer;
    text-transform: uppercase;
    min-width: 19px;
  }

  .item:first-child {
    margin-left: 0;
  }

  .item.active {
    font-weight: 700;
    color: #fff;
  }
}


.change-floor {
  text-align: center;
  margin-top: 38px;

  .ttl {
    font-size: 21px;
    line-height: 25px;
    text-transform: uppercase;
  }

  .changer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 21px;
  }

  .current {
    display: block;
    margin: 0 22px;
    cursor: pointer;

    span {
      font-size: 48px;
      line-height: 52px;
      font-weight: 700;
    }
  }

  .prev,
  .next {
    cursor: pointer;
  }

  .prev.disable,
  .next.disable {
    pointer-events: none;
    opacity: 0.5;
  }
}

//render
.render-img-fixed {
  opacity: 0;
  position: fixed;
  top: 55px;
  left: 0;
  width: 100%;
  z-index: 90;
  pointer-events: none;
  padding-top: 0 !important;
  transform: translateY(-40px);
  transition: opacity 0.2s ease-in-out, transform 0.6s ease-in-out;
  background: #fff;
}

.render-img-fixed.active {
  opacity: 1;
  transform: translateY(0);
}

.render-img {
  padding-top: 20px;
}

.render-img::v-deep {
  polygon {
    position: relative;
    transition: all .45s ease;
    opacity: 0;
    fill: rgba(159, 170, 123, 0.5);
    cursor: pointer;

    &.sold {
      fill: rgba(251, 218, 218, 1) !important;
      mix-blend-mode: unset;
      opacity: .7 !important;
      cursor: default;
    }

    &.reserved {
      fill: #827FA8 !important;
      mix-blend-mode: unset;
      opacity: .5 !important;
    }
  }

  svg {
    position: relative;
  }
}

.flats-render-list {
  .header-content {
    padding: 0 16px;

    .header-title {
      text-align: center;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
    }

    .blocks-list {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 28px;


      .item {
        display: flex;
        align-items: center;

        &:last-child {
          margin-left: 17px;
        }

      }
    }

    .active-icon {
      width: 26px;
    }
  }

  .flats-list {
    padding: 47px 16px 0;

    .flats-list-item {
      width: 100%;
      height: 260px;
      background: #F1F1F1;
      padding: 20px;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }

      .up {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .image {
          width: 134px;
        }
      }

      .flat-text {
        font-weight: 400;
        font-size: 21px;
        align-items: center;
      }
    }
  }
}

.flat-list-wrapper {
  padding: 16px;

  .flat-item {
    margin-bottom: 12px;
  }

  .flat-item:last-child {
    margin-bottom: 0;
  }
}
</style>