<template>
  <div class="apartments-map">
    <template v-if="activeRoute === 'project'">
      <MainTitle v-if="false" :data="data"/>
      <template v-if="!mobile">
        <div v-if="false" class="apartments-map__tabs flex al-center j-center pb-89">
          <div v-for="tab in tabs" :key="tab.title" :class="{ active: selectedTab === tab.title }"
               class="tab-item pointer mr-34 flex al-center uppercase" @click="tabClick(tab)">
            <span class="flex al-center" v-html="comfort"></span> {{ $fn.tr(tab.title) }}
          </div>
        </div>
        <div class="map">
          <div v-if="!activeTab.svgLayer" class="preloader-container">
            <div class="preloader"></div>
          </div>
          <template v-else>
            <h4 class="main-ttl" :class="{ 'hide': !show_title }">{{ $fn.tr("Choose The Block") }}</h4>
            <div class="top-left-cut cut"></div>
            <div class="bottom-left-cut cut">
              <div></div>
              <div></div>
            </div>
            <div class="top-right-cut cut"></div>
            <div class="left-top-block"></div>
            <transition mode="out-in" name="fade">
              <div id="map" :key="activeTab.ui" v-html="activeTab.svgLayer">
              </div>
            </transition>
            <div class="pin" :class="{ active: pin.active }" v-for="(pin, index) in pins" :style="{ ...pin.positions }">
              <div class="pin__label">{{ pin.data.title }}</div>
              <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="32" cy="32" r="32" fill="white"/>
                <path
                    d="M48.2883 22.0955L31.9549 15.0961C31.6653 14.968 31.3346 14.968 31.0451 15.0961L14.7117 22.0955C14.5065 22.1927 14.3318 22.3439 14.2063 22.533C14.0807 22.7223 14.0094 22.9421 14 23.1689V41.8341C14.0003 42.0629 14.0677 42.2869 14.1943 42.4778C14.3208 42.6686 14.5008 42.8181 14.7117 42.9074L31.0451 49.9068C31.3357 50.0311 31.6643 50.0311 31.9549 49.9068L48.2883 42.9074C48.4992 42.8181 48.6792 42.6686 48.8057 42.4778C48.9323 42.2869 48.9997 42.0629 49 41.8341V23.1689C48.9906 22.9421 48.9193 22.7223 48.7937 22.533C48.6682 22.3439 48.4935 22.1927 48.2883 22.0955ZM30.3336 47.0602L16.3336 41.0641V24.942L30.3336 30.9382V47.0602Z"
                    fill="#060606"/>
              </svg>
            </div>
            <div v-if="blockPopUp" :style="{ ...blockPopUp.positions }" class="project-pop-up">
              <img class="background" src="../../../assets/imgs/subtract.png" alt="">
              <div class="content">
                <span class="title">{{ blockPopUp.data.title }}</span>
                <span class="title">{{ $fn.tr(blockPopUp.data.additional_teaser) }}</span>
                <span class="description">{{ blockPopUp.data.teaser }}</span>
              </div>
            </div>
            <svg width="119" height="115" viewBox="0 0 119 115" class="down-icon" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M63.0098 99.4915L63.0098 0.132325L55.0098 0.132324L55.0098 98.3567L5.83263 49.1796L0.175778 54.8364L56.7487 111.409L59.5771 114.238L62.4056 111.409L118.979 54.8364L113.322 49.1796L63.0098 99.4915Z"
                    fill="white"/>
            </svg>
          </template>
        </div>
      </template>
      <!-- <mobile-project-render :data="data" v-else /> -->
      <div v-else class="mob">
        <h2 class="ttl">{{ $fn.tr('CHOOSE BLOCK') }}</h2>
        <div class="mob-list">
          <div class="block-mob-item" v-for="item in blocks_mob" :key="item.key" @click="onBlockClick(item)">
            <div class="left-top-cut cut"></div>
            <div class="right-bottom-cut cut"></div>
            <img :src="item.image" alt="">
            <div class="content">
              <svg width="26" height="42" viewBox="0 0 26 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M25.5 0.485352V32.0399L9.42857 41.4854V9.99101L19.4078 4.13004H4.96429V33.2856H0.5V0.485596L25.5 0.485352Z"
                    fill="white"/>
              </svg>
              <span class="title">{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="!mobile">
      <BlocksRender v-if="activeRoute === 'block'"></BlocksRender>
      <FloorRender v-if="activeRoute === 'floor'"></FloorRender>
    </template>
    <template v-else>
      <mobile-flat-render v-if="activeRoute === 'block' || activeRoute === 'floor'"/>
    </template>
  </div>
</template>

<script setup>
import MainTitle from "@/components/smartComponents/MainTitle/MainTitle";
import { useMenuStore } from '@/store/pinia/menu/index';
import { comfort } from '@/components/UI/svgs.js';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import MobileProjectRender from '@/components/flats/MobileProjectRender.vue';
import { useProjects } from "@/store/pinia/projects";
import BlocksRender from '@/components/smartComponents/BlocksRender/BlocksRender.vue';
import FloorRender from '@/components/smartComponents/FloorRender/FloorRender.vue';
import MobileFlatRender from '@/components/flats/MobileFlatRender.vue';
import { useRoute, useRouter } from "vue-router";

let props = defineProps({
  data: {
    type: Object,
  }
});
let route = useRoute();
const menuStore = useMenuStore();
let projectStore = useProjects();
let router = useRouter();

const show_title = ref(true);
const buildings = ref();
const selectedPins = ref([]);
const tabs = computed(() => props.data?.pins?.map(item => ({
  ...item,
  image: item.attachedImages?.[0]?.devices?.desktop
})));
const selectedTab = ref('Comfort');
const activeTab = computed(() => tabs.value?.find(item => item.title === selectedTab.value) || {});
const blockPopUp = ref(null);
const pins = computed(() => {
  const pinList = activeTab.value.pins;
  if (!pinList) return;
  return pinList.map(pin => {
    return {
      positions: { left: pin.x + '%', top: pin.y + '%' },
      data: pin.data[menuStore.getLang] || {},
    };
  });
});
const polygons = computed(() => activeTab.value?.polygons);
let currentLang = computed(() => menuStore.getLang);
let activeRoute = computed(() => projectStore.activeRoute);
let mobile = computed(() => window.innerWidth < 1024);
let tabClikcTimeout = ref(null);
const blocks = computed(() => projectStore._blocks);
let blocks_mob = computed(() => {
  return projectStore._blocks?.filter(item => !item?.conf?.includes('sold')).map(item => ({
    ...item,
    image: item.mobileimages?.[0]?.devices?.desktop
  }));
});
const hoverPolygon = () => {
  let polygonsDom = document.querySelectorAll('#map polygon');

  polygonsDom.forEach(item => {
    let polygonId = Number(item.getAttribute("polygonid"));

    let polygonInfoId = 0;
    for (const [key, value] of Object.entries(polygons.value)) {

      if (value.ui === polygonId) polygonInfoId = key;
    }

    let block = projectStore._blocks?.find(item => item.id === polygons.value?.[polygonInfoId]?.relatedData);
    if (block?.conf?.includes('sold')) item.classList.add('sold');

    item.addEventListener('mouseover', () => {
      show_title.value = false;

      let itemPosition = item.getBoundingClientRect();

      blockPopUp.value = {
        data: polygons.value?.[polygonInfoId]?.data?.[currentLang.value] || {},
        positions: {
          left: itemPosition.x + itemPosition.width + 'px',
          top: itemPosition.y - itemPosition.height / 2 + 'px'
        }
      };
    });
    item.addEventListener('mouseleave', () => {
      show_title.value = true;
      blockPopUp.value = null;
    });
    item.addEventListener('click', () => {
      if (route.path?.includes('/apview/block')) return;

      let polygonId = Number(item.getAttribute("polygonid"));

      let polygonInfoId = 0;
      for (const [key, value] of Object.entries(polygons.value)) {
        if (value.ui === polygonId) polygonInfoId = key;
      }

      let blockId = polygons.value?.[polygonInfoId]?.relatedData;
      let block = projectStore._blocks?.find(item => item.id === blockId);

      if (block?.conf?.includes('sold')) return;

      let path = route.path.substr(route.path.length - 1) === '/' ? route.path.slice(0, -1) : route.path;

      router.push({
        path: `${ path }/apview/block-${ block.slug }/`,
        query: route.query
      });
    });
  });
};
const onBlockClick = (block) => {
  if (route.path?.includes('/apview/block')) return;
  if (block?.conf?.includes('sold')) return;
  let path = route.path.substr(route.path.length - 1) === '/' ? route.path.slice(0, -1) : route.path;

  router.push({
    path: `${ path }/apview/block-${ block.slug }/floor-0/`,
    query: route.query
  });
};
const setActiveRoute = () => {
  let routeParams = route.params;
  let ifBlock = routeParams.path4?.includes('block');
  let ifFloor = routeParams.path5?.includes('floor');

  if (ifBlock) projectStore.setActiveRoute('block');
  if (ifFloor) projectStore.setActiveRoute('floor');

  if (!ifFloor && !ifBlock) projectStore.setActiveRoute('project');


};
const tabClick = (tab) => {
  if (tabClikcTimeout.value) clearTimeout(tabClikcTimeout.value);
  selectedTab.value = tab.title;
  menuStore.Loco.scrollTo(500);

  projectStore.projectActiveTab = tab.title;
  tabClikcTimeout.value = setTimeout(() => {
    hoverPolygon();
  }, 1000);
};

watch(activeTab, () => {
  selectedPins.value = [];
});
watch(activeRoute, (val, old) => {
  if (val !== old) {
    menuStore.scrollToTopLoco = !menuStore.scrollToTopLoco;
  }
});
watch(blocks, (val, old) => {
  if (val !== old) {
    setTimeout(() => {
      hoverPolygon();
    }, 700);
  }
}, { immediate: true });

let renderTimeOut = ref(null);

onMounted(async () => {
  setActiveRoute();
  selectedTab.value = tabs.value?.[0]?.title;
});

onBeforeUnmount(() => {
  if (renderTimeOut.value) clearTimeout(renderTimeOut.value);
});
</script>

<style lang="scss" scoped>
.cut {
  display: none;
}

.apartments-map {
  &__tabs {
    .tab-item {
      position: relative;
      padding-left: 30px;
      @include easeOut(0.4s, all);
      transition-delay: 0.3s;

      span {
        opacity: 0;
        visibility: hidden;
        transition: 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.2s;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-10px, -50%);
      }

      @include hoverLineAnimation(-10px, 1px);

      @include mq(tablet-sm) {
        font-size: 14px;
      }

      @include hover {
        padding-left: 30px;

        span {
          visibility: visible;
          opacity: 1;
          transform: translate(0px, -50%);

          transition-delay: 0.4s;
        }
      }

      &.active {
        padding-left: 30px;

        span {
          visibility: visible;
          opacity: 1;
          transform: translate(0px, -50%);

          transition-delay: 0.4s;
        }
      }
    }

    @include mq(mobile) {
      display: none !important;
    }
  }

  ::v-deep .map {
    position: relative;
    min-height: 60vh;
    background: #857EAB;
    display: flex;
    align-items: center;
    justify-content: center;

    .preloader-container {
      max-width: 400px;
      margin: 0 auto;
      position: relative;

      .preloader {
        margin: 20% auto 0;
        transform: translateZ(0);
      }

      .preloader:before,
      .preloader:after {
        content: '';
        position: absolute;
        top: 0;
      }

      .preloader:before,
      .preloader:after,
      .preloader {
        border-radius: 50%;
        width: .7em;
        height: .7em;
        animation: animation 1.2s infinite ease-in-out;
      }

      .preloader {
        animation-delay: -0.16s;
      }

      .preloader:before {
        left: -2.5em;
        animation-delay: -0.32s;
      }

      .preloader:after {
        left: 2.5em;
      }

      @keyframes animation {
        0%,
        80%,
        100% {
          box-shadow: 0 .7em 0 -.6em white;
        }
        40% {
          box-shadow: 0 .7em 0 0 white;
        }
      }
    }

    .cut {
      position: absolute;
      background: white;
      z-index: 12;

      &.top-left-cut {
        top: 0;
        left: 0;
        width: 200px;
        height: 50px;
        margin-top: -2px;
      }

      &.top-right-cut {
        top: 0;
        right: 0;
        width: 30px;
        height: 100px;
        margin-top: -2px;
      }

      &.bottom-left-cut {
        bottom: 0;
        left: 0;
        margin-bottom: -2px;
        background: transparent;

        div {
          background: white;
          height: 30px;
          width: 160px;

          &:first-child {
            width: 130px;
          }
        }
      }
    }

    svg {
      polygon {
        opacity: 0;
        fill: #ffffff;
        stroke: #fff;
        transition: 0.3s;
        cursor: pointer;
        pointer-events: all;
        will-change: transform;
        mix-blend-mode: overlay;

        &.sold {
          cursor: default;
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }

    #map {
      width: 100%;

      svg {
        width: 100%;
      }
    }

    .down-icon {
      position: absolute;
      left: 24px;
      bottom: 95px;

      @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        left: 16px;
        bottom: 80px;
        width: 90px;
      }

      @media only screen and (max-width: 1439px) and (min-width: 1024px) {
        left: 10px;
        bottom: 70px;
        width: 70px;
      }
    }
  }

  .pin {
    position: absolute;
    cursor: pointer;

    &__label {
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 21px;
      line-height: 25px;
      color: $white;
      background-color: $black;
      border-radius: 19px;
      padding: 4px 13px;
      margin-bottom: 22px;
      opacity: 0;
      width: max-content;
      pointer-events: none;
      visibility: hidden;
      transition: .3s;
    }

    svg {
      width: 34px;
      height: 34px;

      circle {
        transition: .3s;
      }
    }

    @include hover {
      .pin__label {
        opacity: 1;
        visibility: visible;
      }

      svg {
        circle {
          fill: #D9D9D9;
        }
      }
    }
  }

  .project-pop-up {
    position: absolute;
    background: transparent;
    z-index: 1;
    min-width: 230px;
    padding: 33px 29px;

    .background {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    .content {
      z-index: 1;
      position: relative;
    }

    .title {
      display: block;
      font-size: 60px;
      line-height: unset;

      &:nth-child(2) {
        font-size: 34px;
      }
    }

    .description {
      margin-top: 34px;
      display: block;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s cubic-bezier(0, .12, .22, 1);
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }
}

.main-ttl {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 34px;
  transition: all 0.5s cubic-bezier(0, .12, .22, 1);
}

.main-ttl.hide {
  opacity: 0;
  pointer-events: none;
}

@media screen and(max-width: 1439px) {
  .main-ttl {
    font-size: 21px;
  }
}


.mob {
  margin-top: 34px;

  .ttl {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
}

.mob-list {
  margin-top: 18px;
}

.block-mob-item {
  position: relative;
  background: black;
  margin-top: 24px;

  &:last-child {
    margin-top: 0;
  }

  .cut {
    position: absolute;
    background: white;
    width: 67.65px;
    height: 18.58px;
    z-index: 1;
    display: block;

    &.left-top-cut {
      left: 0;
      top: 0;
    }

    &.right-bottom-cut {
      right: 0;
      bottom: 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  img {
    width: 100%;
    height: 343px;
    object-fit: cover;
  }

  .content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .title {
      font-weight: 400;
      font-size: 54px;
      color: white;
      margin-left: 11px;
      text-transform: uppercase;
    }
  }
}</style>
