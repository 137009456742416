<template>
  <div class="filter-container"
       :class="{active:selectedValue.length||activeOptionList.length||inputModel}"
       v-click-outside="() => (isOpen = false)">
    <div
        :class="{ open: isOpen }"
        class="filter-container__title pointer j-btw flex al-center"
        @click="isOpen = !isOpen"
    >
      <p>{{ title }}</p>
      <span class="ml-13 icon-container">
        <svg width="15" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.999817 1.30975L9.80957 10.1195L18.6193 1.30975" stroke="#D9D9D9" stroke-width="2"/>
</svg>
      </span>
    </div>
    <div
        :class="[{ open: isOpen }, { 'filter-container__dropdown-grid': hasTwoRows }]"
        :style="{ minWidth: width + 'px' }"
        class="filter-container__dropdown filter-container__dropdown-desktop">
      <div v-if="range.length" class="range">
        {{ rangeTitle }}
      </div>
      <vue-slider v-if="range.length"
                  v-model="selectedValue"
                  v-bind="sliderOptions">
        <template v-slot:dot>
          <svg
              class="custom-dot pointer"
              width="42"
              height="32"
              viewBox="0 0 42 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.5" y="0.726013" width="41" height="30" fill="white" stroke="black"/>
            <line x1="21.5" y1="6.22601" x2="21.5" y2="25.226" stroke="black"/>
          </svg>
        </template>
      </vue-slider>
      <div v-else v-for="(item, index) in transformedOptionList"
           class="list-item"
           :class="{active:item.active}"
           @click="setActiveOption(item)"
           :key="index">
        {{ optionListParser ? item[optionListParser] : item }}
      </div>
      <div v-if="showInput" class="input-container">
        <span class="title">{{ inputTitle }}</span>
        <input :placeholder="$fn.tr('Apartement Number')" v-model="inputModel" type="text">
      </div>
      <div class="save pointer"
           @click="saveEvent">{{ $fn.tr('save') }}</div>
    </div>
    <div :class="[{ 'filter-container__dropdown-grid': hasTwoRows }]" class="filter-container__dropdown-mobile">
      <slide-up-down v-model="isOpen" :duration="400">
        <div class="flex column">
          <div v-if="range.length" class="range">{{ rangeTitle }}</div>
          <vue-slider v-if="range.length"
                      v-model="selectedValue"
                      @change="emit('input',$event)"
                      v-bind="sliderOptions">
            <template v-slot:dot>
              <svg
                  class="custom-dot pointer"
                  width="42"
                  height="32"
                  viewBox="0 0 42 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="0.5" y="0.726013" width="41" height="30" fill="white" stroke="black"/>
                <line x1="21.5" y1="6.22601" x2="21.5" y2="25.226" stroke="black"/>
              </svg>
            </template>
          </vue-slider>
          <div v-else v-for="(item, index) in transformedOptionList"
               :class="{active:item.active}"
               @click="setActiveOption(item)"
               class="list-item" :key="index" href="">
            {{ optionListParser ? item[optionListParser] : item }}
          </div>
          <div v-if="showInput" class="input-container">
            <input :placeholder="$fn.tr('Apartement Number')" v-model="inputModel" type="text">
          </div>
          <div v-if="range.length" class="save pointer" @click="isOpen = false">{{ $fn.tr('save') }}</div>
        </div>
      </slide-up-down>
    </div>
  </div>
</template>

<script setup>
import {computed, watch, ref} from 'vue';
import {arrowDown} from '@/components/UI/svgs.js';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import SlideUpDown from 'vue3-slide-up-down';

let props = defineProps({
  title: {
    type: String,
    default:
        '',
  },
  inputTitle: {
    type: String,
    default:
        '',
  },
  interval: {
    type: Number,
    default: 1
  },
  range: {
    type: Array,
    default:
        () => [],
  },
  symbol: {
    type: String,
    default:
        '',
  },
  width: {
    type: Number,
    default:
        230,
  },
  optionList: {
    type: Array,
    default:
        () => [],
  },
  activeOptionListProp: {
    type: Array,
    default:
        () => [],
  },
  optionListParser: {
    type: String,
    required: false
  },
  hasTwoRows: {
    type: Boolean,
  },
  showInput: {
    type: Boolean,
    default: false
  },
  isPrice: {
    type: Boolean
  },
  disableOptionsOnMobile: {
    type: Boolean,
    default: false
  }
})

const isOpen = ref(false);
const selectedValue = ref(props.range);
const sliderOptions = ref({
  max: props.range[1],
  min: props.range[0],
  width: '80%',
  interval: props.interval,
  railStyle: {
    backgroundColor: '#000000',
    height: '1px',
  },
  processStyle: {
    backgroundColor: '#000000',
    height: '1px',
  },
  tooltipStyle: {
    display: 'none',
  },
})
const rangeTitle = computed(() => {
  const {isPrice, symbol } = props;
  const priceSymbol = (isPrice && symbol) || '';
  const nonPriceSymbol = (!isPrice && symbol) || '';

  return `${ priceSymbol }${ selectedValue.value[0] === 0 ? 'L' : selectedValue.value[0] } ${ nonPriceSymbol } -
      ${ priceSymbol }${ selectedValue.value[1] } ${ nonPriceSymbol }`;
});
let activeOptionList = ref(props.activeOptionListProp || []);
const emit = defineEmits(['input']);

let transformedOptionList = computed(() => props.optionList?.map(item => ({
  ...item,
  active: activeOptionList.value?.find(elem => elem?.[props.optionListParser] === item?.[props.optionListParser])
})))
let inputModel = ref(null)

const setActiveOption = (item) => {
  if (props.disableOptionsOnMobile && window.innerWidth < 1024) {
    activeOptionList.value = [item]
    emit('input', activeOptionList.value)
    return;
  }
  const isExist = activeOptionList.value.find(value => value?.[props.optionListParser] === item.title);

  if (isExist) { // only splice array when item is found
    activeOptionList.value = activeOptionList.value.filter(value => value?.[props.optionListParser] !== item.title)
    emit('input', activeOptionList.value)
    return
  }
  activeOptionList.value.push(item)
  emit('input', activeOptionList.value)
}
const saveEvent = () => {
  isOpen.value = false
  if (inputModel.value) emit('input', inputModel.value)
  if (props.range.length) emit('input', selectedValue.value)
}

watch(() => props.range, (first, second) => {
  if (!second[0] || !second) return;
  sliderOptions.value.max = props.range[1];
  sliderOptions.value.min = props.range[0];

  selectedValue.value = first;
});

</script>

<style lang="scss" scoped>
.filter-container {
  &__dropdown {
    position: absolute;
    z-index: 10;
    border: 1px solid $black;
    background-color: $white;
    margin-left: -40px;
    width: 230px;
    @include easeInOut(0.4s, all);
    opacity: 0;
    visibility: hidden;

    &.open {
      opacity: 1;
      visibility: visible;
      transition-delay: 0.1s;

      a {
        opacity: 1;
        visibility: visible;
      }
    }

    a {
      color: $black;
      opacity: 0;
      visibility: hidden;
      @include easeInOut(0.4s, all);
      transition-delay: 0.2s;
      @include hover {
        text-decoration: underline;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 40px;
      height: 21px;
      background-color: $white;
      z-index: 10;
      top: -1.5px;
      left: -1.5px;
      border-bottom: 1px solid $black;
      border-right: 1px solid $black;
    }

    &::before {
      content: '';
      position: absolute;
      width: 40px;
      height: 21px;
      background-color: $white;
      z-index: 10;
      bottom: -1.5px;
      right: -1.5px;
      border: 1px solid $white;
      border-left: 1px solid $black;
      border-top: 1px solid $black;
    }

    &-desktop {
      @include mq(mobile) {
        display: none !important;
      }
    }

    &-mobile {
      display: none !important;
      @include mq(mobile) {
        display: block !important;
        padding: 0 0 21px 0 !important;
        .slide-up-down__container {
          .column {
            display: grid;
            gap: 20px;
            padding: 40px 0;
            margin-right: 40px;
          }

          .range {
            text-align: center;
            position: absolute;
            top: 0px;
            right: 30px;
          }

          .save {
            display: none;
          }

          .vue-slider {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }

      .list-item {
        text-transform: uppercase;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &.active {
    .icon-container {
      path {
        stroke: black;
      }
    }

    .filter-container__title {
      &::after {
        background-color: black !important;
      }
    }
  }

  .icon-container {
    path {
      stroke: #D9D9D9;
    }
  }
}

.filter-container {
  position: relative;

  &__title {
    position: relative;
    padding-bottom: 10px;

    span {
      @include easeInOut(0.4s, all);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.2);
      opacity: 1;
      visibility: visible;
      @include easeInOut(0.2s, all);
      transition-delay: 0.1s;
    }

    &.open {
      span {
        transform: rotate(180deg);
      }

      &::after {
        opacity: 0;
        visibility: hidden;
      }

      @include mq(mobile) {
        &::after {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    p {
      font-size: 21px;
      @include mq(tablet) {
        font-size: 18px;
      }
      @include mq(tablet-sm) {
        font-size: 12px;
      }
      @include mq(mobile) {
        font-size: 21px;
        line-height: 25px;
      }
    }
  }

  .input-container {
    width: 100%;

    .title {
      font-weight: 400;
      font-size: 21px;
      text-align: center;
      display: block;
      margin: 0 auto;
      line-height: unset;
    }

    input {
      border: unset;
      width: 100%;
      border-bottom: 1px solid black;
      padding-bottom: 14px;
      margin-bottom: 15px;
    }
  }

  &__dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 21px;
    position: absolute;
    z-index: 10;
    border: 1px solid $black;
    background-color: $white;
    margin-left: -40px;
    width: 230px;
    gap: 25px;
    opacity: 0;
    visibility: hidden;
    @include easeInOut(0.4s, all);

    &.open {
      opacity: 1;
      visibility: visible;
      transition-delay: 0.1s;

      a {
        opacity: 1;
        visibility: visible;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 40px;
      height: 21px;
      background-color: $white;
      z-index: 10;
      top: -1.5px;
      left: -1.5px;
      border-bottom: 1px solid $black;
      border-right: 1px solid $black;
    }

    &::before {
      content: '';
      position: absolute;
      width: 40px;
      height: 21px;
      background-color: $white;
      z-index: 10;
      bottom: -1.5px;
      right: -1.5px;
      border: 1px solid $white;
      border-left: 1px solid $black;
      border-top: 1px solid $black;
    }

    .list-item {
      margin-bottom: 45px;
      text-align: center;
      cursor: pointer;
      transition: all .45s ease;

      &:hover {
        opacity: .7;
      }

      &.active {
        text-decoration: underline;
      }

      &:first-child {
        margin-top: 45px;
      }
    }

    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 25px;
      padding: 70px 21px;

      .list-item {
        margin-bottom: 0px;
        text-align: center;

        &.active {
          text-decoration: underline;
        }

        &:first-child {
          margin-top: 0px;
        }
      }
    }

    a {
      color: $black;
      opacity: 0;
      visibility: hidden;
      @include easeInOut(0.4s, all);
      transition-delay: 0.2s;
      @include hover {
        text-decoration: underline;
      }
    }

    .save {
      margin-right: auto;
    }
  }
}

.custom-dot {
  margin-top: -8px;
}
</style>
