<template>
  <div class="tabs-outer">
    <div id="tabs-sticky"></div>
    <div id="project-tabs" data-scroll
         data-scroll-sticky
         data-scroll-position="top"
         data-scroll-target="#tabs-sticky" class="project-tabs ">
      <div class="tabs flex j-center ">
        <p
            v-for="tab in tabs"
            :key="tab.title"
            :class="{ active: activeTab === tab.title }"
            class="pointer"
            @click="setActiveTab(tab)"
        >
          {{ tab.label }}
        </p>
      </div>
    </div>
    <div id="scroll_tabs"></div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from 'vue';
import Infrastructure from '../Infrastructure/Infrastructure.vue';
import Terms from '../Terms/Terms.vue';
import { useMenuStore } from "@/store/pinia/menu";
import { useRoute, useRouter } from "vue-router";
import fn from '@/helpers/func';

let store = useMenuStore();
let router = useRouter();
let route = useRoute();

const tabs = [
  {
    title: 'projectDetails',
    label: fn.tr('Project Details'),
    url_slug: 'project-details'
  },
  {
    title: 'infrastructure',
    label: fn.tr('Infrastructure'),
    url_slug: 'infrastructure'
  },
  {
    title: 'gallery',
    label: fn.tr('Gallery'),
    url_slug: 'gallery'
  },
  {
    title: 'terms',
    label: fn.tr('Terms'),
    url_slug: 'terms'
  },
];

const activeTab = ref('projectDetails');
let cache = computed(() => store.cache);

let setActiveTab = (tab, scroll = true) => {
  cache.value.showHiddenComponent = tab.title;

  activeTab.value = tab.title;

  router.replace({ query: { tab: tab.url_slug } });
  if (scroll) store.Loco.scrollTo('#scroll_tabs');
  store.triggerUpdate = !store.triggerUpdate;
  setTimeout(() => {
    store.triggerUpdate = !store.triggerUpdate;
  }, 1000);
  return false;
};
onMounted(async () => {
  let activeTabFromUrl = tabs.find(item => item.url_slug === route?.query?.tab) || tabs[0];
  setActiveTab(activeTabFromUrl, false);
  await nextTick();
});
</script>

<style lang="scss">
.tabs-outer {
  position: relative;
  margin-bottom: 89px;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}

#tabs-sticky {
  position: fixed;
  pointer-events: none;
  opacity: 0;
  z-index: -1;
}

#scroll_tabs {
  position: absolute;
  top: 0;
  opacity: 0;
  pointer-events: none;
  width: 0;
}

.project-tabs {
  position: relative;
  padding: 70px 0;
  background: transparent;
  color: black;
  transition: padding .45s ease-out, background .45s ease-out, color .45s ease-out;
  will-change: transform;
  @media (max-width: 767px) {
    padding: 0 16px 20px;
    overflow-x: auto;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    background: transparent;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    transition: all .45s ease-out;
    will-change: transform;
    width: 1820px;


    @include mq(desktop-lg) {
      width: calc(100% - 70px);
    }

    @include mq(tablet) {
      width: calc(100% - 50px);
    }

    @include mq(mobile) {
      width: calc(100% - 42px);
    }
  }

  &.stick {
    background: white;
    z-index: 121212;
    padding: 40px 0;

    &:after {
      background: rgba(0, 0, 0, .7);
    }

    @media (max-width: 1440px) {
      padding: 30px 0;
    }
  }

  .tabs {
    p {
      font-size: 28px;
      line-height: 33px;
      position: relative;
      @include mq(tablet-sm) {
        font-size: 18px;
        min-width: fit-content;
        text-decoration: none;
        &:before {
          display: none;
        }
      }

      &:not(:last-child) {
        margin-right: 30px;
      }

      @include hoverLineAnimation;
    }

    @include mq(tablet-sm) {
      padding-bottom: 89px;
    }
    @include mq(mobile) {
      padding-bottom: 21px;
      justify-content: unset !important;
    }
  }
}

.project-tabs__content {
  position: absolute;
  top: 100px;
  height: 500vh;
  pointer-events: none;
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
