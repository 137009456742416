import { defineStore } from "pinia";
import func from "../../../helpers/func";
import { useMenuStore } from "@/store/pinia/menu";
// import { useMenuStore } from "../menu";
//
// let menuStore = useMenuStore(); 

export const useProjects = defineStore("todos", {
    state: () => ({
        activeApartmentTypes: [],
        activeApartmentBlocks: [],
        activeApartmentFloors: [],
        activeApartmentCategories: [],
        _activeProject: {},
        areaRange: [],
        priceRange: [],
        _projects: null,
        _flats: null,
        _types: null,
        _blocks: null,
        _floors: null,
        _flat_number: null,
        _flat_type: null,
        orderBy: "asc",
        orderField: "price",
        _currency: {
            code: "USD",
        },
        isQueryParameters: false,
        disableShowMore: 1,
        projectActiveTab: '',
        _activeRoute: '',
        _loadingFlats: false,
        data: {},
    }), getters: {
        getActiveApartmentType(state) {
            return state.activeApartmentTypes;
        }, flats(state) {
            return state._flats;
        }, activeProject(state) {
            return state._activeProject;
        }, currency(state) {
            return state._currency;
        }, getIsQueryParameters(state) {
            return state.isQueryParameters;
        }, activeRoute(state) {
            return state._activeRoute;
        }, loadingFlats(state) {
            return state._loadingFlats;
        },
    }, actions: {
        async getAvaliableFlatsByFloor(id) {
            return new Promise(async (resolve, reject) => {
                let { data } = await func.postData("dev/getFlats", {
                    conf: 'free', block_id: id
                });
                if (!data?.list?.length) {
                    resolve([]);
                } else {
                    const groupedData = data.list.reduce((acc, obj) => {
                        const floor_number = obj.floor_number;
                        if (!acc[floor_number]) {
                            acc[floor_number] = [];
                        }
                        acc[floor_number].push(obj);
                        return acc;
                    }, {});
                    resolve(groupedData);
                }
            });
        }, async getProjects(activeProjectSlug) {
            let { data } = await func.postData('dev/getProjects');
            let store = useProjects();

            this._projects = data.list?.map(item => ({
                ...item, value: item.id, label: item.title
            })) || [];

            if (activeProjectSlug) store.setActiveProject(activeProjectSlug);
        }, setActiveProject(activeProjectSlug) {
            this._activeProject = this._projects?.find(item => {
                return item.slug === activeProjectSlug;
            });

        }, async getTypes(projectId, id) {
            let project_id = projectId || this._activeProject.id;

            let queryData = {
                project_id, id, limit: 1500
            };
            let { data } = await func.postData('dev/getTypes', queryData);

            this._types = data.list;
        }, async getBlocks() {
            let { data } = await func.postData('dev/getBlocks', { project_id: this._activeProject.id });

            this._blocks = data.list;
        }, async getFloors() {
            let { data } = await func.postData('dev/getFloors', {
                blockId: this._blocks?.map(item => item.id), limit: 1000
            });
            this._floors = data.list;
        },
        async getFlatsFun(pageNumber = 1, merge = false, limit = 25) {
            return new Promise(async (resolve, reject) => {
                let priceMin = Math.floor(this.priceRange[0]);
                let priceMax = Math.floor(this.priceRange[1]);
                let floor_number = Array.isArray(this.activeApartmentFloors) ? this.activeApartmentFloors?.map((item) => Number(item)) : [];
                this._loadingFlats = true;

                let { data } = await func.postData("dev/getFlats", {
                    project_id: this.activeProject?.id,
                    type_id: this._flat_type,
                    block_id: this.activeApartmentBlocks,
                    price: priceMin ? [priceMin, priceMax] : null,
                    development_types_area_m2: this.areaRange[0] ? [this.areaRange[0], this.areaRange[1]] : null,
                    floor_number,
                    categoryId: this.activeApartmentCategories,
                    flat_number: this._flat_number,
                    orderDirection: 'asc',
                    orderField: 'conf',
                    page: pageNumber,
                    limit: limit
                });
                resolve(data);
            });
        },
        async getFlats(pageNumber = 1, merge = false, limit = 25) {
            let priceMin = Math.floor(this.priceRange[0]);
            let priceMax = Math.floor(this.priceRange[1]);
            let floor_number = Array.isArray(this.activeApartmentFloors) ? this.activeApartmentFloors?.map((item) => Number(item)) : [];
            this._loadingFlats = true;

            let { data } = await func.postData("dev/getFlats", {
                project_id: this.activeProject?.id,
                type_id: this._flat_type,
                block_id: this.activeApartmentBlocks,
                price: priceMin ? [priceMin, priceMax] : null,
                development_types_area_m2: this.areaRange[0] ? [this.areaRange[0], this.areaRange[1]] : null,
                floor_number,
                categoryId: this.activeApartmentCategories,
                flat_number: this._flat_number,
                orderDirection: 'asc',
                orderField: 'conf',
                page: pageNumber,
                limit: limit
            });
            this._loadingFlats = false;
            let transform_data = data?.list?.map(item => {
                let type = this._types?.find(type => {
                    return type.id === item?.type_id;
                }) || {};
                let store = useMenuStore();
                let flatTypes = store.indx?.terms?.Flats_Type;
                let viewType = store.indx?.terms?.View_Type;

                return {
                    ...item,
                    block: this._blocks?.find(block => block.id === item.block_id) || {},
                    type: type,
                    flatType: flatTypes?.find(item => type.taxonomy?.Flats_Type?.includes(item.id)) || {},
                    viewType: viewType?.filter(item => type.taxonomy?.View_Type?.includes(item.id)) || {},
                    price: Math.floor(item.price_m2 * item.development_types_area_m2)
                };
            });

            merge ? this._flats = [...this._flats, ...transform_data] : this._flats = transform_data;

            if (this._flats?.length === data.listCount && data.listCount > limit) {
                this.disableShowMore = 0;
                return;
            }
            if (data.listCount <= limit) {
                this.disableShowMore = -1;
            } else {
                data?.list?.length <= 0 ? this.disableShowMore = 0 : this.disableShowMore = 1;
            }


        }, updateFlatsPrice(currency) {
            this._currency = currency;
        }, setQueryParameters(data) {
            if (data.activeApartmentTypes) this.activeApartmentTypes = data.activeApartmentTypes;
            if (data.activeApartmentBlocks) this.activeApartmentBlocks = data.activeApartmentBlocks;
            this.activeApartmentFloors = data.activeApartmentFloors;
            if (data.areaRange) this.areaRange = data.areaRange;
            if (data.priceRange) this.priceRange = data.priceRange || [];
            if (data.activeApartmentCategories) this.activeApartmentCategories = data.activeApartmentCategories;
            if (data.orderBy) this.orderBy = data.orderBy;
            if (data.orderField) this.orderField = data.orderField;
            if (data.flat_number) this._flat_number = data.flat_number === 'null' ? undefined : data.flat_number;
            if (data.flat_type) this._flat_type = data.flat_type;
            if (data.view) this._flat_type = data.view;
        }, setIsQueryParameters(value) {
            this.isQueryParameters = value;
        }, setActiveRoute(value) {
            this._activeRoute = value;
        }
    },
});
