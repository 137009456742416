import {defineStore} from 'pinia'
import func from "../../../helpers/func";
import _remove from "lodash/remove";

export const UseFavoriteFlats = defineStore('favorite', {
    state: () => ({
        _favoriteFlatIds: JSON.parse(localStorage.getItem("apartmentIds")) || [], _flats: [], _showFavoriteList: false,_showContactSale: false,_showNav: false
    }), getters: {
        favoriteFlatIds(state) {
            return state._favoriteFlatIds
        }, flats(state) {
            return state._flats
        }, showFavoriteList(state) {
            return state._showFavoriteList
        },
        showContactSale(state) {
            return state._showContactSale
        },
        showNav(state) {
            return state._showNav
        },

    }, actions: {
        async getFavoriteFlats() {
            let {data} = await func.postData('dev/getFlats', {
                ids: this._favoriteFlatIds
            })
            this._flats = data
        },
        addFavorite( flatId) {
            let apartmentsIds = JSON.parse(localStorage.getItem("apartmentIds")) || [];

            if (apartmentsIds?.indexOf(flatId) === -1) {
                apartmentsIds.push(flatId);
            } else {
                _remove(apartmentsIds, item => item === flatId)
            }

            window.localStorage.setItem("apartmentIds", JSON.stringify(apartmentsIds));

            this._favoriteFlatIds = apartmentsIds
            this.getFavoriteFlats()
        },
        setFavoriteListVisibility(value) {
            this._showFavoriteList = value
        },
        setContactSale(value) {
            this._showContactSale = value
        },
        setNav(value) {
            this._showNav = value
        }

    },
})
