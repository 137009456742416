<template>
  <div class="house-finder container">
    <div class="house-finder__desktop">
      <div id="house_finder" class="house-finder__title text-center">{{ $fn.tr('House Finder') }}</div>
      <div @click="resetFilter" class="reset pointer">
        <div v-if="windowWidth > 1488" class="reset-button">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.9431 2.57761C16.5751 4.07897 18.3043 6.96284 18.3043 10.2098C18.3043 11.9349 17.7928 13.6213 16.8343 15.0557C15.8759 16.4901 14.5136 17.6081 12.9198 18.2683C11.326 18.9285 9.57218 19.1012 7.88019 18.7647C6.18819 18.4281 4.63399 17.5974 3.41413 16.3775C2.19427 15.1576 1.36354 13.6034 1.02698 11.9115C0.69042 10.2195 0.863154 8.46566 1.52334 6.87184C2.18352 5.27801 3.3015 3.91575 4.73591 2.95731C6.17031 1.99887 7.85671 1.4873 9.58186 1.4873"
                stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.9434 6.93888V2.57764H18.3046" stroke="black" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
          <span>{{ $fn.tr('Reset Filter') }}</span>
        </div>

        <svg width="22" height="22" v-else viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1.41406" width="28" height="2" transform="rotate(45 1.41406 0)" fill="black"/>
          <rect x="0.414062" y="19.7988" width="28" height="2" transform="rotate(-45 0.414062 19.7988)" fill="black"/>
        </svg>
      </div>
      <div v-if="flats" class="house-finder__filters">
        <RangeFilter @input="dataChangedEvent($event, 'activeApartmentBlocks')" :title="$fn.tr('Block')"
                     optionListParser="title" :activeOptionListProp="blockFromRoute" :optionList="blocks"/>
        <RangeFilter :title="$fn.tr('Type')" @input="dataChangedEvent($event, 'flat_type')" optionListParser="title"
                     :optionList="flatType"/>
        <RangeFilter :title="$fn.tr('Floor')"
                     @input="dataChangedEvent($event, 'activeApartmentFloors')"
                     :range="floorRange"/>
        <RangeFilter :title="$fn.tr('Price range')" @input="dataChangedEvent($event, 'priceRange')" isPrice
                     :range="priceRange" symbol="$" :width="406"/>
        <RangeFilter :title="$fn.tr('Number')"
                     :inputTitle="$fn.tr('Type In')" @input="dataChangedEvent($event, 'flat_number')"
                     :show-input="true" :width="406"/>
        <RangeFilter :title="$fn.tr('m2')" @input="dataChangedEvent($event, 'areaRange')" :range="areaRange"
                     :interval="0.01" symbol="m2" :width="406"/>
        <RangeFilter :title="$fn.tr('View')" optionListParser="title" @input="dataChangedEvent($event, 'view')"
                     :optionList="viewType"/>
      </div>
      <template v-if="flats?.length">
        <div class="house-finder__row pointer" v-for="(i, index) in flats" @click="goToFlat(i)" :key="index">
          <div class="house-finder__column">{{ i.block.title }}</div>
          <div class="house-finder__column">{{ i.flatType.title }}</div>
          <div class="house-finder__column">{{ i.floor_number }}th</div>
          <div class="house-finder__column">{{ numberFormat(i.price) }} $</div>
          <div class="house-finder__column">{{ i.flat_number }}</div>
          <div class="house-finder__column">{{ i.type.area_m2 }}m<sup>2</sup></div>
          <div class="house-finder__column">
            <p v-for="view in i.viewType">{{ view.title }}</p>
          </div>
          <span v-html="arrowRight"></span>
        </div>
      </template>
      <template v-else-if="!flats?.length && !is_loading">
        <div class="not-result">
          <h4 class="ttl">{{ $fn.tr('We are sorry') }}</h4>
          <p class="txt">{{
              $fn.tr('We can’t seem to find apartment that match your search. Please change or reset the filter')
            }}</p>
        </div>
      </template>
      <div @click="showMore" v-if="disableShowMore === 1" id="show_more" class="show-more-button">
        <span class="title">{{ $fn.tr('Show more options') }}</span>
      </div>
      <div @click="showLess" v-if="disableShowMore === 0" class="show-more-button show-less">
        <span class="title">{{ $fn.tr('Show less options') }}</span>
      </div>
    </div>
    <div v-if="isMobile" class="house-finder__mobile">
      <div class="flex j-center al-center text" @click="isOpenFilter = true">
        <p class="mobile-header">{{ $fn.tr('House Finder') }}</p>
        <span class="flex" v-html="arrowRight"></span>
      </div>
      <!-- <Portal target="#popup-target"> -->
      <Teleport to="body">
        <div v-if="flats" class="filter-popup" :class="{ visible: isOpenFilter }">
          <div class="top flex al-center j-btw">
            <div class="title">Filters</div>
            <span class="pointer" @click="isOpenFilter = false" v-html="cross"></span>
          </div>
          <RangeFilter @input="dataChangedEvent($event, 'activeApartmentBlocks')" :title="$fn.tr('Block')"
                       optionListParser="title" :disableOptionsOnMobile="true" :activeOptionListProp="blockFromRoute"
                       :optionList="blocks" :hasTwoRows="true"/>
          <RangeFilter :title="$fn.tr('Type')" @input="dataChangedEvent($event, 'flat_type')" optionListParser="title"
                       :optionList="flatType"/>
          <RangeFilter :title="$fn.tr('Floor')" @input="dataChangedEvent($event, 'activeApartmentFloors')"
                       :disableOptionsOnMobile="true" :optionList="floorRangeMobile" optionListParser="title"
                       :hasTwoRows="true"/>
          <RangeFilter :title="$fn.tr('Price range')" @input="dataChangedEvent($event, 'priceRange')" isPrice
                       :range="priceRange" symbol="$" :width="406"/>
          <RangeFilter :title="$fn.tr('Number')" :inputTitle="$fn.tr('Type In')"
                       @input="dataChangedEvent($event, 'flat_number')"
                       :show-input="true" :width="406"/>
          <RangeFilter :title="$fn.tr('m2')" @input="dataChangedEvent($event, 'areaRange')" :range="areaRange"
                       symbol="m2"
                       :width="406"/>
          <RangeFilter :title="$fn.tr('View')" optionListParser="title" @input="dataChangedEvent($event, 'view')"
                       :optionList="viewType"/>
          <div class="bottom flex al-center j-btw">
            <p @click="mobileSaveEvent" class="uppercase pointer">{{ $fn.tr('save') }}</p>
            <p class="uppercase pointer">{{ $fn.tr('reset') }}</p>
          </div>
        </div>
      </Teleport>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import RangeFilter from './Filters/RangeFilter.vue';
import { arrowRight, cross } from '@/components/UI/svgs.js';
import { useRoute, useRouter } from "vue-router";
import func from "../../../helpers/func";
import { useProjects } from "@/store/pinia/projects";
import { useMenuStore } from "@/store/pinia/menu";
import { handleResize } from '@/composables/resizeHandler';
import { numberFormat } from '@/composables/numberFormat';

let route = useRoute();
let router = useRouter();
let projectStore = useProjects();
let menuStore = useMenuStore();
let activeProjectSlug = route.params?.path2;

const isOpenFilter = ref(false);
let page = ref(1);
const { windowWidth } = handleResize();
let projects = computed(() => projectStore._projects);
let flats = computed(() => projectStore._flats?.filter(item => !item.conf?.includes('sold') && !item.conf?.includes('reserved')) || null);
const is_loading = computed(() => {
  return projectStore._loadingFlats;
});
let floor = computed(() => projectStore._floors);
let blocks = computed(() => {
  return projectStore._blocks?.filter(item => !item?.conf?.includes('sold'))?.sort((a, b) => {
    return (a.slug > b.slug) ? 1 : ((b.slug > a.slug) ? -1 : 0);
  });
});
let types = computed(() => projectStore._types);
let disableShowMore = computed(() => projectStore.disableShowMore);
let flatType = computed(() => {
  return menuStore.indx?.terms?.Flats_Type || [];
});
let viewType = computed(() => menuStore.indx?.terms?.View_Type);
let floorRange = computed(() => {
  let item = menuStore.indx?.terms?.floor_range?.[0] || {};
  console.log(item.min, item);
  return [item.min === 'L' ? parseInt(0) : parseInt(item.min), parseInt(item.max)];
});
let floorRangeMobile = computed(() => {
  let floors = [];
  for (let i = floorRange.value[0]; i <= floorRange.value[1]; i++) {
    floors.push({
      title: i,
      id: i
    });
  }

  return floors;
});
let priceRange = computed(() => {
  let item = menuStore.indx?.terms?.price_range?.[0] || {};

  return [parseInt(item.min_price), parseInt(item.max_price)];
});
let areaRange = computed(() => {
  let item = menuStore.indx?.terms?.m2_range?.[0] || {};

  return [parseInt(item.min), parseInt(item.max)];
});
let activeProject = computed(() => projects.value?.find(item => {
  return item.slug === activeProjectSlug;
}) || {});
let blockFromRoute = computed(() => {
  let block = blocks.value?.find(item => item.slug?.toLowerCase() === route?.params?.path4?.split('-')?.[1]?.toLowerCase());

  return block ? [block] : [];
});
let floorFromRoute = computed(() => {
  let floor_number = route?.params?.path5?.split('-')?.[1];

  return floor_number && [Number(floor_number)];
});
let queryFilterData = ref({});
let isMobile = computed(() => window.innerWidth < 1024);

const showMore = () => {
  page.value = page.value + 1;

  projectStore.getFlats(page.value, true);
};
const showLess = () => {
  page.value = 1;

  projectStore.getFlats(page.value).then(() => {
    menuStore.Loco.scrollTo('#house_finder');
  });
};
const resetFilter = () => {
  page.value = 1;
  projectStore.setQueryParameters({
    activeApartmentTypes: [],
    activeApartmentBlocks: [],
    activeApartmentFloors: [],
    activeApartmentCategories: [],
    _activeProject: {},
    areaRange: [],
    priceRange: [],
    flat_number: 'null',
    development_types_area_m2: null,
    flat_type: []
  });
  projectStore.getFlats();
};
const dataChangedEvent = (e, field, getFlat = true) => {
  page.value = 1;
  let data = {};

  data[field] = Array.isArray(e) ? e.map(item => item.id ? item.id : item) : e;

  if (field === 'flat_type') {
    let activeTypes = [];
    e.forEach(item => {
      let flat_types = types.value?.filter(type => type?.taxonomy?.Flats_Type?.includes(item.id))?.map(type => type.id);

      activeTypes = [...activeTypes, ...flat_types];
    });

    data[field] = activeTypes;
  }
  if (field === 'view') {
    let activeTypes = [];
    e.forEach(item => {
      let view_types = types.value?.filter(type => type?.taxonomy?.View_Type?.includes(item.id))?.map(type => type.id);

      activeTypes = [...activeTypes, ...view_types];
    });

    data[field] = activeTypes;
  }
  projectStore.setQueryParameters(data);
  queryFilterData.value = {
    ...queryFilterData.value,
    ...data
  };
  if (!getFlat || window.innerWidth < 1024) return;

  projectStore.getFlats();
};
let isNumber = (value) => {
  return typeof value === 'number' && isFinite(value);
};
const mobileSaveEvent = () => {
  projectStore.getFlats().then(() => {
    let query = queryFilterData.value;
    let path = route.path.substr(route.path.length - 1) === '/' ? route.path.slice(0, -1) : route.path;
    let mainPath = path.split('/')?.splice(0, 4)?.join('/');
    let blockFromQuery = blocks.value?.find(item => item.id === query?.activeApartmentBlocks?.[0]);
    let floorFromQuery = query?.activeApartmentFloors?.[0];

    let blockId = blockFromQuery?.title || route?.params?.path4?.split('-')?.[1];
    let floorId = floorFromQuery || route?.params?.path5?.split('-')?.[1];
    let blockPath = blockId ? `block-${ blockId }` : 'block';
    let floorPath = floorId ? `floor-${ floorId }` : 'floor';

    router.push({
      path: `${ mainPath }/apview/${ blockPath }/${ floorPath }`,
      query: route.query
    });
  });
};
const goToFlat = (item) => {
  let routePath = route.path.substr(route.path.length - 1) === '/' ? route.path.slice(0, -1) : route.path;
  let block = blocks.value?.find(block => block.id === item?.block_id);

  router.push({
    path: `${ routePath }/apview/block-${ block.slug }/floor-${ item.floor_number }/${ item.id }-apartment-${ item.flat_number }`,
    query: route.query
  });
};
projectStore.getProjects();
const getData = async () => {
  if (!projectStore._activeProject) await projectStore.getProjects(activeProjectSlug);
  if (!projectStore._types) await projectStore.getTypes();
  if (!projectStore._blocks) await projectStore.getBlocks();

  dataChangedEvent(blockFromRoute.value, 'activeApartmentBlocks', false);
  dataChangedEvent(floorFromRoute.value, 'activeApartmentFloors', false);
  if (!projectStore._floors) projectStore.getFloors();
  setTimeout(() => {
    projectStore.getFlats();
  }, 0);
};

onMounted(() => {
  getData();
});
</script>

<style lang="scss">
.house-finder {
  padding-top: 65px;
  padding-bottom: 35px;

  &__title {
    font-size: 48px;
    padding-bottom: 79px;

    @include mq(tablet-sm) {
      font-size: 24px;
      line-height: 29px;
      padding-bottom: 36px;
    }
  }

  &__filters {
    padding-bottom: 30px;
    display: grid;
    gap: 60px;
    margin-right: 15%;
    grid-template-columns: 120px 1fr 120px 1fr 120px 150px 1fr;

    @include mq(desktop-sm) {
      margin-right: 15%;
      grid-template-columns: 85px 142px 100px 130px 120px 120px 1fr;
    }

    @include mq(tablet) {
      gap: 30px;
      grid-template-columns: 75px 120px 100px 120px 80px 110px 75px;
    }

    @include mq(tablet-sm) {
      gap: 20px;
      grid-template-columns: 60px 100px 77px 90px 65px 75px 45px;
    }
  }

  &__row {
    display: grid;
    margin-right: 15%;
    grid-template-columns: 120px 1fr 120px 1fr 120px 150px 2fr;
    padding: 22px;
    margin-left: -22px;
    gap: 60px;
    position: relative;
    width: 100%;
    align-items: center;

    @include mq(desktop-sm) {
      grid-template-columns: 85px 142px 100px 130px 120px 120px 1fr;
    }

    @include mq(tablet) {
      gap: 30px;
      grid-template-columns: 75px 120px 100px 120px 80px 110px 75px;
    }

    @include mq(tablet-sm) {
      gap: 20px;
      grid-template-columns: 60px 100px 77px 90px 65px 75px 45px;
      padding-right: 12%;
    }

    span {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      opacity: 0;
      visibility: hidden;
      @include easeInOut(0.2s, all);
    }

    @include easeInOut(0.2s, all);

    @include hover {
      background-color: #f1f1f1;

      span {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__column {
    @include mq(tablet) {
      font-size: 18px;
    }

    @include mq(tablet-sm) {
      font-size: 12px;
    }

  }

  &__desktop {
    display: block;

    @include mq(mobile) {
      display: none;
    }
  }

  &__mobile {
    display: none;

    @include mq(mobile) {
      display: block;
    }

    .mobile-header {
      @include mq(mobile) {
        font-size: 21px !important;
      }

    }

    .text {
      p {
        font-size: 34px;
        line-height: 40px;
        margin-right: 22px;
      }
    }
  }

  .reset {
    position: absolute;
    right: 60px;
    padding-bottom: 10px;
    border-bottom: 1px solid $black;

    @include mq(desktop-sm) {
      right: 32px;
    }

    @include mq(tablet) {
      font-size: 18px;
    }

    @include mq(tablet-sm) {
      font-size: 12px;

      svg {
        width: 15px;
      }
    }
  }

  .show-more-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 70px;
    background: #F1F1F1;

    .title {
      font-weight: 400;
      font-size: 21px;
      color: #000000;
    }
  }
}

.filter-popup {
  display: flex;
  flex-direction: column;

  &.visible {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background: white;
    padding: 20px;
    overflow-y: auto;

    .list-item,
    .range,
    .save {
      @include mq(mobile) {
        font-size: 21px;
        line-height: 25px;
      }
    }
  }

  .top {
    padding-bottom: 50px;

    .title {
      font-size: 34px;
      line-height: 40px;
    }
  }

  .bottom {
    margin-top: auto;
    padding-bottom: 100px;

    p {
      font-size: 20px;
      line-height: 24px;
      text-decoration-line: underline;
    }
  }
}


.not-result {
  margin-bottom: 20px;
  margin-top: 50px;

  .ttl {
    font-size: 74px;
  }

  .txt {
    display: block;
    font-size: 34px;
    margin-top: 17px;
  }
}

@media screen and (max-width: 1599px) {
  .not-result {
    .ttl {
      font-size: 55px;
    }

    .txt {
      font-size: 34px;
    }
  }
}


@media screen and (max-width: 1249px) {
  .not-result {
    margin-top: 20px;

    .ttl {
      font-size: 34px;
    }

    .txt {
      display: block;
      font-size: 21px;
      margin-top: 17px;
    }
  }
}


@media screen and (max-width: 767px) {
  .not-result {
    margin-top: 16px;

    .ttl {
      font-size: 21px;
    }

    .txt {
      display: block;
      font-size: 16px;
      margin-top: 17px;
    }
  }
}

.reset-button {
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
}
</style>
