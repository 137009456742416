
import {defineComponent, defineAsyncComponent, computed, ref, onMounted, watch, unref} from 'vue';
import mainMixin from './mixins/main';
import pageRouting from './mixins/pageRouting';
import resize_handler from './mixins/resize_handler';
import {useHead} from '@vueuse/head';
import {useMenuStore} from './store/pinia/menu/index';
import {mapState} from 'pinia';
import {useRoute} from 'vue-router';
import get from 'lodash/get';
import fn from "@/helpers/func";

export default defineComponent({
  name: 'App',
  mixins: [mainMixin, pageRouting, resize_handler],
  components: {},
  data() {
    return {
      selectedLayout: '',
      prevSelectedLayout: '',
      old_y: 0,
      scrollTimeout: null,
    };
  },
  mounted() {
    if (this.windowWidth <= 1023) {
      document.querySelector<HTMLElement>('html').style.height = window.innerHeight + 'px';
      document.querySelector<HTMLElement>('body').style.height = window.innerHeight + 'px';
      document.querySelector<HTMLElement>('#app').style.height = window.innerHeight + 'px';
    }
  },
  beforeCreate() {
    if (!this.$options.components) {
      this.$options.components = {};
    }
  },
  computed: {
    ...mapState(useMenuStore, [
      'selected_menu',
      'indx',
      'isBurgerMenuOpen',
      'curosrText',
      'isHeaderOpaque',
      'cursorComponent',
    ]),
    smartLayoutsState() {
      return this.indx.smartLayouts;
    },
    selectedМenu() {
      return this.selected_menu;
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(ns) {
        this.pageRoutingSelectMenuByPath();
        if (this.$route.name === 'ge') {
          document.querySelector('html').setAttribute('lang', 'ka');
        } else if (this.$route.name === 'ru') {
          document.querySelector('html').setAttribute('lang', 'ru');
        } else {
          document.querySelector('html').setAttribute('lang', 'en');
        }

        if (ns.href && ns.name === 'no_name') {
          let no_name_params = ns.params;

          this.$router.push({name: this.indx.locale, params: no_name_params});
          document.querySelector('html').setAttribute('lang', 'ka');
        }
      },
    },
    'selected_menu.id': {
      immediate: true,
      handler() {
        this.setLayoutTemplate();
      },
    },
    smartLayoutsState: {
      handler() {
        this.pageRoutingSelectMenuByPath();
        this.setLayoutTemplate();
      },
    },
  },
  methods: {
    setLayoutTemplate(): void {
      this.selectedLayout = this.selected_menu.currentLayoutTemplate;
      this.$options.components.layout = defineAsyncComponent(() => import('./views/layouts/' + this.selectedLayout));
    },
  },
  setup() {
    const store = useMenuStore();

    const route = useRoute();

    watch(
        route,
        () => {
          store.getComponentCursor(null, null);
        },
        {
          immediate: true,
          deep: true,
        }
    );
    // website title

    const innerSeo = computed(() => {
      return get(store?.current_content, `primary.data.list[0].seo_title_${store?.locale}`);
    });
    const singleView = computed(() => {
      return Object.values(route.params).includes('singleview');
    });

    const seoTitle = computed(() =>
        store?.selected_menu.seo?.title
            ? store?.selected_menu.seo?.title
            : store?.selected_menu?.title
                ? store?.selected_menu?.title
                : 'Cityzen'
    );

    // website keywords
    const keywords = computed(() => {
      store.selected_menu.seo?.keywords || store.indx?.website_meta_keywords;
    });
    // website destcription
    const contentDescription = computed(() => store.selected_menu.seo?.description);

    const websiteTitle = computed(() => store.indx?.siteSettings?.website_title || 'Cityzen Project');

    const outerSeoTitleWrapper = computed(() => seoTitle.value + ' | ' + websiteTitle.value);

    useHead({
      title: computed(() => {
        if (!innerSeo.value && singleView.value) {
          return get(store?.current_content, 'primary.data.list[0].title') + ' | ' + websiteTitle.value;
        } else {
          return outerSeoTitleWrapper.value;
        }
      }),
      meta: [
        {
          name: `description`,
          content: contentDescription,
        },
        {
          name: 'keyword',
          content: keywords,
        },
      ],
    });

    return {
      store,
    };
  },
});
