<template>
  <div class="wrapper">
    <div class="top">
      <div v-if="prev" class="prev val" @click="onChange(prev)">
        {{ prev.floor_number === 0 ? 'L' :prev.floor_number }}
        <div class="layer"></div>
      </div>
      <div class="current val" @click="showOptions = !showOptions">
        {{ activeItem?.floor_number === 0 ? 'L' : activeItem?.floor_number }}
      </div>
      <div v-if="next" class="next val" @click="onChange(next)">
        {{ next.floor_number === 0 ? 'L' :next.floor_number }}
        <div class="layer"></div>
      </div>

      <div class="button" :class="{ 'active': showOptions }" @click="showOptions = !showOptions">
        <svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M23.6205 3.3492L12.9768 14.0246C12.8501 14.1513 12.7128 14.2408 12.565 14.2932C12.4172 14.3464 12.2588 14.373 12.0898 14.373C11.9209 14.373 11.7625 14.3464 11.6147 14.2932C11.4668 14.2408 11.3296 14.1513 11.2029 14.0246L0.527472 3.3492C0.231813 3.05354 0.0839839 2.68396 0.0839838 2.24048C0.0839838 1.79699 0.242372 1.41686 0.55915 1.10008C0.875927 0.783302 1.2455 0.624913 1.66787 0.624913C2.09024 0.624913 2.45981 0.783302 2.77659 1.10008L12.0898 10.4133L21.4031 1.10008C21.6988 0.80442 22.0628 0.65659 22.4953 0.656589C22.9287 0.656589 23.3038 0.814979 23.6205 1.13176C23.9373 1.44853 24.0957 1.81811 24.0957 2.24048C24.0957 2.66285 23.9373 3.03242 23.6205 3.3492Z"
              fill="black"/>
        </svg>
      </div>
    </div>
    <transition name="fade-up-custom">
      <div v-if="options.length && showOptions" key="fadeup" class="options" v-click-outside="onClickOutside">
        <div class="opt-inner">
          <div class="opt-list" data-scroll-section id="inner-scroll-div">
            <div class="opt-item" v-for="opt in options" :key="opt.key" :class="{ 'active': opt.active }"
                 @click="onChange(opt)">
              <span>{{ $fn.tr(label) }}</span> &nbsp; &nbsp;
              <span>{{ opt.floor_number === 0 ? 'L' : opt.floor_number }}</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onBeforeUnmount } from 'vue';
import { useMenuStore } from '@/store/pinia/menu';

const props = defineProps({
  options: {
    type: Array,
    default: []
  },
  label: {
    type: String,
    default: 'Label'
  },
  event: {
    type: Function,
  },
  activeOption: {
    type: Object,
    default: () => {
    }
  }
});
const store = useMenuStore();
const activeItem = ref(props.activeOption);

const showOptions = ref(false);
const Loco = computed(() => {
  return store.Loco;
});
const prev = computed(() => {
  return props.options[activeItem.value?.floor_number - 1] || false;
});
const next = computed(() => {
  return props.options[activeItem.value?.floor_number + 1] || false;
});
const onChange = (item) => {
  activeItem.value = item;
  showOptions.value = false;
  if (props.event) props.event(item);
};
const onClickOutside = () => {
  showOptions.value = false;
};

onMounted(() => {
  if (!props.activeOption) activeItem.value = props.options?.find(item => item.active);
  window.addEventListener('mousewheel', scroll_control);
});
onBeforeUnmount(() => {
  window.removeEventListener('mousewheel', scroll_control);
});
const scroll_control = (event) => {
  var divElement = document.getElementById('inner-scroll-div');
  if (!divElement) return false;
  var divRect = divElement.getBoundingClientRect();

  var mouseX = event.clientX;
  var mouseY = event.clientY;

  if (
      mouseX >= divRect.left &&
      mouseX <= divRect.right &&
      mouseY >= divRect.top &&
      mouseY <= divRect.bottom
  ) {
    var element = divElement;
    if (
        event.deltaY > 0 &&
        element.scrollHeight - element.scrollTop === element.clientHeight
    ) {
      Loco.value.start();
    } else if (
        event.deltaY < 0 &&
        element.scrollTop === 0
    ) {
      Loco.value.start();
    } else {
      Loco.value.stop();
    }
  } else {
    Loco.value.start();
  }
};
</script>
<style>
.fade-up-custom-enter-active,
.fade-up-custom-leave-active {
  transition: .6s cubic-bezier(.22, -0.01, .34, 1);
}

.fade-up-custom-enter-from,
.fade-up-custom-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
<style lang="scss" scoped>
.wrapper {
  position: relative;
  z-index: 20;
  padding: 80px 0;
}

.top {
  position: relative;
  display: flex;
  align-items: center;
}

.val {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 34px;
  width: 80px;
  height: 80px;
}

.current {
  border: 2px solid #000000;
}

.prev {
  position: absolute;
  top: -100%;
  left: 0;
  cursor: pointer;

  .layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #FFFFFF 19.58%, rgba(255, 255, 255, 0.540156) 71.15%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
  }
}

.next {
  position: absolute;
  bottom: -100%;
  left: 0;

  .layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #FFFFFF 19.58%, rgba(255, 255, 255, 0.540156) 71.15%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
  }
}

.button {
  width: 58px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  svg {
    transition: .4s;
  }
}

.button:hover svg,
.current:hover button svg,
.button.active svg {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  left: 50%;
  top: calc(100% + 5px);
  transform: translateX(-50%);
  background: transparent;

  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0.5, 0.5));
}

.opt-inner {
  padding-right: 3px;
  background: #fff;
  clip-path: polygon(0 6%, 15% 6%, 15% 0%, 85% 0%, 100% 0, 100% 15%, 100% 95%, 85% 95%, 85% 100%, 15% 100%, 0 100%, 0% 85%);
}

.opt-list::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.opt-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.opt-list::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.opt-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.opt-list {
  overflow: auto;
  max-height: 460px;
  display: flex;
  flex-direction: column;
  padding: 57px 57px 57px 63px;

  .opt-item {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #F5F4F4;
    padding: 21px 0;
    cursor: pointer;
    transition: .4s;
  }

  .opt-item:last-child {
    border-bottom: none;
  }

  span {
    font-size: 28px;
    transition: .4s;
  }

  .opt-item:hover span {
    font-weight: 500;
  }

  .opt-item:hover {
    transform: scale(1.2);
  }

  .opt-item.active span {
    font-weight: 500;
  }

  .opt-item.active {
    transform: scale(1.2);
  }

}

@media screen and (max-width: 1900px) {
  .val {
    font-size: 21px;
    width: 40px;
    height: 40px;
  }

  .wrapper {
    padding: 40px 0;
  }

  .button {
    width: 30px;
  }

  .button svg {
    width: 15px;
  }
}

@media screen and (max-width: 1439px) {
}
</style>