<template>
  <div v-if="data" class="terms flex container">
    <div class="row">
      <h3>{{ data.title }}</h3>
    </div>
    <div class="row text" v-html="data.teaser"></div>
  </div>
</template>

<script setup>
import {computed} from "vue";

let props = defineProps({
  contentData: {
    type: Object,
  }
})
let data = computed(() => props.contentData?.data?.list?.[0])
</script>

<style lang="scss">
.terms {
  padding-top: 121px;
  h3 {
    font-size: 64px;
    line-height: 76px;
    @include mq(desktop-sm) {
      font-size: 48px;
      line-height: 58px;
    }
    @include mq(tablet) {
      font-size: 40px;
      line-height: 48px;
    }
    @include mq(tablet-sm) {
      font-size: 24px;
      line-height: 29px;
    }
  }

  .text {
    font-size: 21px;
    line-height: 30px;

    p {
      //margin-bottom: 70px;
    }

    @include mq(tablet-sm) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .row {
    width: 50%;
  }

  @include mq(mobile) {
    flex-direction: column;
    .pt-89 {
      padding-top: 0px;
    }
    .pb-89 {
      padding-bottom: 55px;
    }
    .row {
      width: 100%;
    }
    .text {
      margin-top: 30px;

      p {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
