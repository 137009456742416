<template>
   <div class="contact-banner">
      <div class="img-box">
         <picture>
            <source media="(max-width: 767px)" :srcset="$get(data, 'mobile_image.0.devices.desktop')" />
            <source media="(max-width: 1023px)" :srcset="$get(data, 'image.0.devices.tablet')" />
            <img  :src="$get(data, 'image.0.devices.desktop')"
               :alt="$get(data, 'image.0.title')" />
         </picture>
      </div>
      <div class="layer"></div>
      <div class="content">
         <svg class="line" width="1682" height="478" viewBox="0 0 1682 478" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
               d="M2.25975 103.236L2.25977 476.265L1680.16 476.265L1680.16 33.1589L1483.11 33.1589L1483.11 2.29651L684.09 2.29657L684.09 103.236L2.25975 103.236Z"
               stroke="white" stroke-width="3" />
         </svg>
         <svg class="mob-line" width="347" height="308" viewBox="0 0 347 308" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
               d="M2.36719 78.0585L2.36719 305.56L345.367 305.56L345.367 32.4539L305.086 32.4539L305.086 1.59148L212.557 1.59148L212.557 78.0585L2.36719 78.0585Z"
               stroke="white" stroke-width="3" />
         </svg>

         <h2 class="ttl">{{ $get(data, 'title') }}</h2>
         <div class="bottom">
            <div class="details">
               <a v-if="$get(data, 'link.url')" class="link" :href="`tel:${$get(data, 'link.url')}}`">{{ $get(data,
                  'link.title') }}</a>
               <p class="txt">{{ $get(data, 'teaser') }}</p>
               <button class="btn" @click="openContractSales(true)">
                  <div class="bg"></div>
                  <span>{{ $fn.tr("Send Form") }}</span>
               </button>
            </div>
            <div class="logo">
               <CitizenWhiteLogo />
            </div>
         </div>
      </div>
   </div> 
</template>

<script setup>
import { computed } from 'vue';
import CitizenWhiteLogo from '@/components/UI/CitizenWhiteLogo.vue';
import { UseFavoriteFlats } from '@/store/pinia/FavoriteProjects';

const props = defineProps({
   contentData: {}
})

const store = UseFavoriteFlats()

const data = computed(() => {
   return props.contentData.data?.list[0] || false
})
const openContractSales = (val) => {
   store.setContactSale(val)
}
</script>

<style lang="scss" scoped>
.contact-banner {
   position: relative;
   margin-top: 142px;
}

.img-box {
   position: relative;
   padding-top: 30.9%;

   img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
   }
}

.layer {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.content {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;

   padding: 11% 9% 5%;
}

.line {
   display: block;
   width: calc(100% - 240px);
   height: auto;
   position: absolute;
   top: 50%;
   left: 120px;
   object-fit: cover;
   pointer-events: none;
   transform: translateY(-50%);
}

.mob-line {
   display: none;
}

.ttl {
   position: absolute;
   top: 50px;
   left: 125px;
   width: 33%;
   font-size: 55px;
   font-weight: 400;
   padding-left: 5px;
   color: #fff;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
   white-space: pre-line;
}

.bottom {
   display: flex;
   justify-content: space-between;
   align-items: flex-end;
   height: 100%;
}

.logo {
   width: 41.2%;

   svg {
      display: block;
      width: 100%;
      height: auto;
   }
}

.details {
   display: flex;
   flex-direction: column;
   flex: 1;
   height: 100%;
   padding-right: 24px;


   .link,
   .txt {
      color: #fff;
      font-size: 30px;
      font-weight: 400;
   }

   .txt {
      margin-top: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: pre-line;
   }
}

.btn {
   display: block;
   overflow: hidden;
   position: relative;
   width: 362px;
   height: 109px;
   border: 1px solid #FFFFFF;

   display: flex;
   justify-content: center;
   align-items: center;

   cursor: pointer;
   margin-top: auto;

   span {
      position: relative;
      font-size: 34px;
      color: #fff;
      transition: 0.8s cubic-bezier(.22, -0.01, .34, 1);
   }

   &:hover span {
      color: #000000;
   }

   .bg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      transition: 0.8s cubic-bezier(.22, -0.01, .34, 1);
      transform: translateX(-100%);
      background: white;
   }

   &:hover .bg {
      transform: translateX(0%);
   }
}

@media screen and (max-width: 1900px) {
   .contact-banner{
      margin-top: 80px;
   }
   .ttl {
      left: 100px;
      font-size: 41px;
   }

   .line {
      width: calc(100% - 200px);
      left: 100px;
   }
}

@media screen and(max-width: 1599px) {
   .contact-banner{
      margin-top: 60px;
   }
   .details {

      .txt,
      .link {
         font-size: 21px;
      }
   }

   .btn {
      width: 250px;
      height: 80px;

      span {
         font-size: 21px;
      }
   }
}

@media screen and(max-width: 1439px) {
   .ttl {
      left: 80px;
   }

   .line {
      width: calc(100% - 160px);
      left: 80px;
   }
}


@media screen and (max-width: 1279px) {
   .contact-banner{
      margin-top: 40px;
   }
   .ttl {
      left: 48px;
      font-size: 34px;
      top: 28px;
   }

   .line {
      width: calc(100% - 96px);
      left: 48px;
   }

   .btn {
      width: 180px;
      height: 60px;
   }


   .content {
      padding: 10% 7% 5%;
   }
}

@media screen and (max-width: 1023px) {
   .ttl {
      left: 16px;
      font-size: 21px;
      top: 28px;
   }

   .line {
      width: calc(100% - 32px);
      left: 16px;
   }

   .content {
      padding: 10% 5% 5%;
   }

   .details {

      .txt,
      .link {
         font-size: 16px;
      }
   }

   .btn {
      width: 148px;
      height: 61px;

      .bg {
         transform: translateX(0);
      }

      span {
         font-size: 16px;
         color: #000;
      }
   }
}

@media screen and(max-width: 767px) {
   .contact-banner{
      margin-top: 21px;
   }
   .ttl {
      top: 10%;
      width: 53%;
   }

   .img-box {
      padding-top: 118%;
   }

   .content {
      padding: 32% 16px 34%;
   }

   .line {
      display: none;
   }

   .mob-line {
      display: block;
      position: absolute;
      width: calc(100% - 32px);
      height: auto;
      top: 25px;
      left: 16px;
      pointer-events: none;
   }

   .bottom {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
   }

   .details {
      padding: 0 14px;
   }

   .logo {
      width: 343px;
      max-width: calc(100% - 32px);
      height: auto;
      position: absolute;
      left: 50%;
      bottom: 34px;
      transform: translateX(-50%);
   }

   .txt {
      max-width: 265px;
   }

}

@media screen and(max-width: 320px) {
   .ttl {
      top: 35px;
   }
}
</style>