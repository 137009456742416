<template>
  <div class="contact-form container pt-144">
    <div class="subtitle">
      <span>{{ data.teaser }}</span>
      <a class="underline ml-13" :href="'tel:'+$fn.tr('MobileNumber')">{{ $fn.tr('MobileNumber') }}</a>
    </div>
    <div class="title">{{ data.title }}</div>
    <div class="form flex column">
      <div class="input" :class="{ active: name }">
        <input type="text" v-model="name" :placeholder="$fn.tr('Name')"/>
        <div class="error" :class="name == '' && error ? 'active' : ''">{{ $fn.tr('Please fill field') }}</div>
        <i></i>
        <i></i>
      </div>
      <div class="input input-with-span flex al-center" :class="{ active: subject }">
        <span>{{ $fn.tr('I want to:') }}</span>
        <input
            type="email"
            v-model="subject"
            class="clamp-1"
            :placeholder="$fn.tr('Buy Apartement, Contact Sales')"
        />
        <div class="error" :class="subject == '' && error ? 'active' : ''">{{ $fn.tr('Please fill field') }}</div>
        <i></i>
        <i></i>
      </div>
      <div class="input" :class="{ active: email }">
        <input type="number" v-model="email" :placeholder="$fn.tr('Your number / Email')"/>
        <div class="error" :class="email == '' && error ? 'active' : ''">{{ $fn.tr('Please fill field') }}</div>
        <i></i>
        <i></i>
      </div>
      <div class="input" :class="{ active: message }">
        <input
            type="textarea"
            v-model="message"
            :placeholder="$fn.tr('Write a Message')"
            @keyup.enter="sendReq"
        />
        <i></i>
        <i></i>
      </div>
      <div class="contract-sales__send linkHov" @click="sendReq">
        {{ $fn.tr('Send') }}
      </div>
    </div>
    <div :class="{ show: isMessageShown }" class="success-message relative flex al-center j-center">
      <p>{{ $fn.tr("You message was sent!") }}</p>
      <span class="pointer" @click="isMessageShown = false" v-html="crossWhite"></span>
    </div>
  </div>
</template>

<script setup>
import {computed, nextTick, onMounted, ref, watch} from "vue";
import {UseFavoriteFlats} from '@/store/pinia/FavoriteProjects';
import {crossWhite} from '@/components/UI/svgs.js';
import fn from "@/helpers/func";

const name = ref("");
const email = ref("");
const message = ref("");
const subject = ref("");
const error = ref(false);
const isMessageShown = ref(false);
let store = UseFavoriteFlats();

watch(email, () => {
  if (email.value < 0) {
    email.value = 0
  }
});
let props = defineProps(
    {
      openNav: {
        required: false,
      },
      contentData: {
        type: Object
      }
    }
);
let data = computed(() => props.contentData?.conf?.header || {})
const validateEmail = (email) => {
  return String(email)
      .toLowerCase()
      .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
}

async function sendReq() {
  if (name.value !== '' && email.value !== '' && subject.value !== '') {
    const formData = {
      name: name.value,
      email: email.value,
      message: message.value,
      subject: subject.value,
      formType: "contractSale",
    };
    let tmp = await fn.postData("main/saveSubmitedForm", formData);
    if (tmp.status == 200) {
      name.value = "";
      email.value = "";
      message.value = "";
      subject.value = "";
      isMessageShown.value = true;
    }
    return true;
  } else {
    error.value = true
  }
}
</script>

<style lang="scss">
input {
  font-family: 'HelveticaRoman';
}

input:placeholder {
  font-family: 'HelveticaRoman';
}

.contract-sales__send {
  display: flex;
  margin: 40px auto;
  font-size: 34px;
  line-height: 41px;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 0px;
  font-weight: 900;
}

.contact-form .form .input-with-span span {
  font-family: 'HelveticaRoman';
}

//Laptop
@media (max-width: 1900px) {
  .contract-sales__send {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .contract-sales__send {
    margin-top: 15px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:focus::placeholder {
  color: rgba(0, 0, 0, 0) !important;
}

input:focus ~ i {
  &:last-child {
    transform: scaleX(1) !important;
  }
}

.contact-form {
  .title {
    font-size: 34px;
    padding-bottom: 34px;

    @include mq(desktop-sm) {
      font-size: 21px;
      padding-bottom: 21px;
    }
    @include mq(tablet-sm) {
      font-size: 21px;
    }
    @include mq(mobile) {
      font-size: 16px;
      line-height: 34px;
      max-width: 80%;
    }
  }

  .subtitle {
    * {
      font-size: 55px;
    }

    @include mq(desktop-sm) {
      * {
        font-size: 34px;
      }
    }
    @include mq(tablet-sm) {
      * {
        font-size: 34px;
      }
    }
    @include mq(mobile) {
      line-height: 26px;
      word-break: break-all;
      width: 100%;
      min-width: 100%;
      * {
        font-size: 21px;
      }
      a {
        margin-left: 0;
        display: block;
      }
    }
  }

  .form {
    margin-bottom: 50px;
    @include mq(desktop-sm) {
      margin-bottom: 30px;
    }
    @include mq(tablet-sm) {
      margin-bottom: 20px;
    }

    .input {
      position: relative;
      font-family: HelveticaRoman !important;

      input {
        font-family: HelveticaRoman !important;
      }

      .error {
        position: absolute;
        left: 0px;
        bottom: -25px;
        font-size: 14px;
        color: #dd0d0d;
        transition: 950ms cubic-bezier(0.77, 0, 0.175, 1);
        opacity: 0;
        pointer-events: none;

        &.active {
          opacity: 1;
        }
      }

      input {
        border: unset;
        padding: 34px 0;
        font-size: 34px;
        width: 100%;

        @include mq(desktop-sm) {
          font-size: 21px;
          padding: 21px 0;
        }

        @include mq(mobile) {
          font-size: 21px;
          padding: 16px 0;
        }

        &::placeholder {
          color: $black;
          transition: 950ms transform cubic-bezier(0.77, 0, 0.175, 1);
        }
      }

      i {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        will-change: transform;
        background-color: rgba(0, 0, 0, 0.1);
        transition: all .5s cubic-bezier(.22, -0.01, 0, 1);
        transform-origin: left center;

        &:last-child {
          transform: scaleX(0);
          position: absolute;
          background: $black;
        }
      }
    }

    .input-with-span {
      @include mq(mobile) {
        flex-direction: column;
        align-items: flex-start;
      }

      span {
        font-size: 34px;
        padding-right: 20px;
        @include mq(desktop-sm) {
          font-size: 21px;
          padding: 20px 0;
        }
      }

      input {
        width: 80%;

        &::placeholder {
          color: #9c9c9c;
          transition: 950ms transform cubic-bezier(0.77, 0, 0.175, 1);
        }
      }
    }
  }

  .hint-message {
    font-size: 32px;
    line-height: 38px;
    @include mq(tablet) {
      font-size: 21px;
    }
    @include mq(tablet-sm) {
      font-size: 12px;
    }
    @include mq(mobile) {
      display: none;
    }
  }

  .success-message {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    height: 100px;
    background-color: #000;
    position: relative;
    @include easeOut(0.4s, all);

    &:before {
      content: '';
      position: absolute;
      left: -50px;
      height: 100%;
      width: 50px;
      background: #000;
      top: 0px;
    }

    &:after {
      content: '';
      position: absolute;
      right: -50px;
      height: 100%;
      width: 50px;
      background: #000;
      top: 0px;
    }

    &.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }

    p {
      font-size: 34px;
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0px);
      color: #fff;
      @include mq(tablet-sm) {
        font-size: 23px;
        left: 0px;
        position: absolute;
        transform: none;
        top: 30px;
      }
    }

    span {
      margin-left: auto;
    }
  }

  .pt-144 {
    @include mq(tablet-sm) {
      padding-top: 44px;
    }
  }

  .pb-144 {
    @include mq(tablet-sm) {
      padding-bottom: 44px;
    }
  }
}
</style>
