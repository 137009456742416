<template>
  <div class="floor-render">
    <div class="content">
      <h2 class="header-title">{{ $fn.tr('Choose Your Apartement') }}</h2>
      <div class="blocks-list">
        <div class="item">
          <svg width="25" height="41" viewBox="0 0 25 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 0V31.5545L8.92857 41V9.50566L18.9078 3.64469H4.46429V32.8002H0V0.000244069L25 0Z"
                  fill="black"/>
          </svg>
          <m-select :event="changeBlock" :options="blocks"/>
        </div>
        <div v-if="false" class="item">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 16V8H24V0H32V32H0V24H8V16H16Z" fill="black"/>
          </svg>
          <m-select :event="changeFloor" :options="activeFloors"/>
        </div>
        <div class="item choose-floor">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 16V8H24V0H32V32H0V24H8V16H16Z" fill="black"/>
          </svg>
          <span class="ttl">
            {{ $fn.tr('Floor') }}
          </span>
          <mutant-select :event="changeFloor"
                         v-if="currentFloor"
                         :activeOption="currentFloor"
                         :options="activeFloors" label="floor"/>

        </div>
      </div>
    </div>
    <div class="render-container">
      <div class="render-img" id="floorRender" v-html="renderImg"></div>
      <teleport to="body">
        <transition name="flip">
          <div v-if="blockPopUp" :style="{ ...blockPopUp.positions }" class="floor-pop-up">
            <!--            <img class="background" src="../../../assets/imgs/subtract.png" alt="">-->
            <div class="content">
              <span class="title">{{ blockPopUp.data }}</span>
              <span class="area">{{ blockPopUp.area }}{{ $fn.tr('M') }}<sup>2</sup></span>
              <span v-if="blockPopUp.sold" style="color: #D8000C" class="description">{{ $fn.tr('sold') }}</span>
              <span v-else-if="blockPopUp.reserve" class="description">{{ $fn.tr('reserved') }}</span>
              <span v-else class="description">{{ $fn.tr('Explore') }}</span>
            </div>
          </div>
        </transition>
      </teleport>
    </div>
  </div>
</template>

<script setup>
import { useProjects } from "@/store/pinia/projects";
import { computed, nextTick, onBeforeUnmount, onMounted } from "vue";
import { ref, watch } from "vue";
import { useMenuStore } from "@/store/pinia/menu";
import { useRoute, useRouter } from "vue-router";
import { floor } from "lodash";
import MSelect from '../../UI/MSelect';
import MutantSelect from '../../UI/MutantSelect.vue';
import fn from "../../../helpers/func";

let router = useRouter();
let route = useRoute();
const blockPopUp = ref(null);
let menuStore = useMenuStore();
let projectStore = useProjects();
let BLockFromRoute = computed(() => route?.params?.path4?.split('-')?.[1]);
let FloorFromRoute = computed(() => route?.params?.path5?.split('-')?.[1]);
const all_flats = ref([]);

let renderImg = computed(() => {
  let floor = activeFloors.value?.find(item => item.floor_number === Number(FloorFromRoute.value));

  return floor?.render?.[0]?.svgLayer;
});
const polygons = computed(() => {
  return activeFloors.value?.find(item => item.floor_number === Number(floorNumber.value))?.render?.[0]?.polygons;
});
let currentLang = computed(() => menuStore.getLang);

let floorNumber = computed(() => route.params?.path5?.split('-')?.[1]);
let blockNumber = computed(() => route.params?.path4?.split('-')?.[1]);
let floors = computed(() => projectStore._floors?.map(item => {
  return {
    ...item,
    active: item.floor_number === Number(floorNumber.value),
    title: `${ fn.tr('floor') } ${ item.title }`
  };
}));
let blocks = computed(() => projectStore._blocks?.filter(item => !item.conf?.includes('sold'))
    ?.map(item => {
      return {
        ...item,
        active: item.slug === blockNumber.value,
        title: `${ item.title }`
      };
    }));
let activeBlock = computed(() => blocks.value.find(item => item.slug === BLockFromRoute.value));
let tabClickTimeout = ref(null);
let activeFloors = computed(() => floors.value?.filter(item => item.block_id === activeBlock.value?.id)
    ?.sort((a, b) => a.floor_number - b.floor_number));
const currentFloor = computed(() => {
  let floor_number = route?.params?.path5?.split('-')?.[1];

  return activeFloors.value?.find(item => item.floor_number === Number(floor_number));
});
let loadingState = computed(() => projectStore.loadingFlats);

const mouseleaveEvent = () => {
  blockPopUp.value = null;
};
const mouseoverEvent = (e) => {
  let item = e.target;

  let polygonId = Number(item.getAttribute("polygonid"));

  let polygonInfoId = 0;
  for (const [key, value] of Object.entries(polygons.value)) {

    if (value.ui === polygonId) polygonInfoId = key;
  }

  let itemPosition = item.getBoundingClientRect();
  let flatId = polygons.value?.[polygonInfoId]?.relatedData;
  let flat = projectStore._flats?.find(item => {

    return item.flat_number === flatId;
  });

  blockPopUp.value = {
    data: flatId || '',
    sold: flat?.conf?.includes('sold'),
    reserve: flat?.conf?.includes('reserved'),
    area: flat?.development_types_area_m2,
    positions: {
      left: itemPosition.x < 300 ? itemPosition.x + itemPosition.width + 'px' : itemPosition.x - 290 + 'px',
      top: itemPosition.y < 250 ? itemPosition.y + 50 + 'px' : itemPosition.y + 'px'
    }
  };
};
const markSolidFlats = async (item) => {
  let flats = projectStore._flats;
  let polygonId = Number(item.getAttribute("polygonid"));

  let polygonInfoId = 0;
  for (const [key, value] of Object.entries(polygons.value)) {
    if (value.ui === polygonId) polygonInfoId = key;
  }

  let flat = flats?.find(item => item.flat_number === polygons.value?.[polygonInfoId]?.relatedData);

  return new Promise((resolve, reject) => {
    if (flat?.conf?.includes('sold')) {
      item.classList.add('sold');
      reject();
    } else {
      if (flat?.conf?.includes('reserved')) {
        item.classList.add('reserved');
      }
      resolve();
    }
  });
};

// const setPolygonsStatusClasses = (flats) => {
//   if (!flats?.length) return false;
//   let floor = activeFloors.value?.find(item => item.floor_number === Number(FloorFromRoute.value));
//   let id;
//   flats.forEach((flat) => {
//     console.log(flats.length,'activeFloors.value')
//     id = Object.values(floor.render?.[0].polygons).find((item) => item.relatedData === flat.flat_number)?.ui || false;
//     if (!id) return false;
//     const polygon = document.querySelectorAll(`polygon[polygonid='${id}']`)
//     if (polygon && polygon[0]) {
//       if (flat?.conf?.includes('reserved')) {
//         polygon[0].classList.add('reserved')
//       } else if (flat?.conf?.includes('sold')) {
//         polygon[0].classList.add('sold')
//       }
//     }
//   })
// }

const clickEvent = (e) => {
  let item = e.target;

  let polygonId = Number(item.getAttribute("polygonid"));

  let polygonInfoId = 0;
  for (const [key, value] of Object.entries(polygons.value)) {
    if (value.ui === polygonId) polygonInfoId = key;
  }
  let polygonInfo = polygons.value?.[polygonInfoId];

  let path = route.path.substr(route.path.length - 1) === '/' ? route.path.slice(0, -1) : route.path;
  let flat = projectStore._flats?.find(item => {
    return item.flat_number === polygonInfo?.relatedData;
  });

  router.push({
    path: `${ path }/${ flat.id }-apartment-${ polygonInfo?.relatedData }`,
  });
};
const hoverPolygon = () => {
  let polygonsDom = document.querySelectorAll('#floorRender polygon');

  polygonsDom.forEach(item => {
    item.addEventListener('mouseover', mouseoverEvent);
    item.addEventListener('mouseleave', mouseleaveEvent);
    markSolidFlats(item).then(() => {
      item.addEventListener('click', clickEvent);
    });
  });
};
const changeFloor = (item) => {
  if (tabClickTimeout.value) clearTimeout(tabClickTimeout.value);
  let routePath = route.path?.split('/');
  let floorIndex = routePath?.findIndex(item => item?.includes('floor'));
  routePath[floorIndex] = `floor-${ item.floor_number }`;

  router.push({
    path: `${ routePath.join('/') }`,
    query: route.query
  });

};
const changeBlock = (item) => {
  if (tabClickTimeout.value) clearTimeout(tabClickTimeout.value);
  let routePath = route.path?.split('/');
  let floorIndex = routePath?.findIndex(item => item?.includes('block'));
  routePath[floorIndex] = `block-${ item.slug }`;

  router.push({
    path: `${ routePath.join('/') }`,
    query: route.query
  });
};
const removeEventListeners = () => {
  let polygonsDom = document.querySelectorAll('#floorRender polygon');

  polygonsDom.forEach(item => {
    item.removeEventListener('mouseover', mouseoverEvent);
    item.removeEventListener('mouseleave', mouseleaveEvent);
    item.removeEventListener('click', clickEvent);
  });
};

onMounted(async () => {
  await nextTick();
  setTimeout(() => {
    hoverPolygon();
  }, 200);
  projectStore.getFlatsFun(1, false, 500)
      .then((res) => {
        all_flats.value = res.list;
        // setPolygonsStatusClasses(res.list)
      });
  if (!projectStore._blocks) await projectStore.getBlocks();
  if (!projectStore._floors) await projectStore.getFloors();

});
watch(loadingState, () => {
  setTimeout(() => {
    hoverPolygon();
  }, 200);
});
onBeforeUnmount(() => {
  removeEventListeners();
});
</script>

<style lang="scss" scoped>
.floor-render:deep {
  background: white;
  color: black;
  position: relative;

  .content {
    position: relative;
    z-index: 2;

    .header-title {
      text-align: center;
      font-weight: 400;
      font-size: 64px;
    }

    .blocks-list {
      display: flex;
      align-items: center;
      margin: 0 auto;
      width: fit-content;

      .item {
        display: flex;
        align-items: center;
        text-align: center;
        margin-right: 34px;
        cursor: pointer;

        .select-container {
          margin-left: 10px;
        }

        &:last-child {
          margin-right: 0;
        }

        .title {
          font-weight: 400;
          font-size: 54px;
          color: black;
          margin-left: 10px;
        }
      }


    }
  }

  .render-img {
    svg {
      polygon {
        transition: all .45s ease;
        opacity: 0;
        fill: rgba(159, 170, 123, 0.5);
        cursor: pointer;

        &:hover {
          opacity: .7;
        }

        &.sold {
          fill: rgba(251, 218, 218, 1) !important;
          mix-blend-mode: unset;
          opacity: .7 !important;
          cursor: default;
        }

        &.reserved {
          fill: #827FA8 !important;
          mix-blend-mode: unset;
          opacity: .5 !important;
        }
      }

      rect {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1900px) {
  .floor-render:deep {

    .content {
      .header-title {
        font-size: 48px;
        line-height: auto;
      }
    }

  }

}

@media screen and (max-width: 1439px) {
  .floor-render:deep {

    .content {
      .header-title {
        font-size: 21px;
      }
    }

  }

  .choose-floor .ttl {
    font-size: 21px;
  }
}
</style>

<style lang="scss">
.flip-enter-active,
.flip-leave-active {
  transition: all 0.5s ease-out;
}

.flip-enter-from,
.flip-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}

.floor-pop-up {
  position: absolute;
  background: white;
  clip-path: polygon(0% 3.07%, 10.4% 3.07%, 10.4% 0%, 100% 0%, 100% 96.93%, 90.6% 96.93%, 90.6% 100%, 0% 100%);
  z-index: 99;
  width: 260px;
  padding: 33px 29px;
  box-shadow: 0 4px 22px rgba(0, 0, 0, 0.65);

  .background {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .content {
    z-index: 1;
    position: relative;
  }

  .up {
    .description {
      font-size: 34px;
      display: block;
      font-weight: bold;
      color: black;
      margin-top: 0;
    }

  }

  .title {
    display: block;
    font-size: 55px;
    line-height: 65px;

    &:nth-child(2) {
      font-size: 34px;
    }
  }

  .area {
    font-weight: 400;
    font-size: 24px;
    display: block;
    font-family: HelveticaThin;
  }

  .description {
    display: block;
    margin-top: 34px;
  }
}

.choose-floor {
  display: flex;
  align-items: center;
  margin-left: 67px;

  .ttl {
    font-size: 54px;
    margin: 0 34px;
  }
}

@media screen and (max-width: 1900px) {
  .choose-floor {

    .ttl {
      font-size: 34px;
    }
  }
}
</style>