import {defineStore, storeToRefs} from "pinia";

import get from "lodash/get";
import find from "lodash/find";
import size from "lodash/size";
import fn from "../../../helpers/func";
import {menu_mutation} from "./menu_helpers";
import {StateInterface} from "../../ts/interfaces/state.interface";

export const useMenuStore = defineStore({
    // id is required so that Pinia can connect the store to the devtools
    id: "menu",
    state: (): StateInterface => ({
        mapKey: null,
        indx: {
            menus: {},
            locales: {},
            locale: "",
            siteSettings: {
                website_title: "",
            },
            website_meta_keywords: "",
            contentTypes: {},
            smartLayouts: {},
        }, /// all the main data loaded by default
        conf: {}, /// project global configuration
        cache: {}, /// all the data tahat comes while website runing
        tmp: {}, /// temporary data for short use
        current_content: {
            secondary: {},
        }, /// to store all the content types data
        current_content_buffer: {}, /// to store current content before use new values
        current_content_request_status: "", /// status for current content loaded/// 'pending' while request; 'done' after loaded content from server; 'error' if there is server error
        current_content_last_request: "", /// latest current content request stringify. /// to controll request dublications
        smart_components_load_status: "",
        selected_menu: {
            configs: [],
            id: null,
            inserted_at: "",
            media: {},
            menu_type: "",
            pid: null,
            primary_data: [],
            primary_template: "",
            redirect_url: "",
            secondary_data: [],
            secondary_template: "",
            seo: {
                title: "",
                description: "",
                keywords: "",
            },
            set_home: null,
            sort: null,
            titles: {},
            updated_at: "",
            url_slug: "",
            currentLayoutTemplate: "",
        },
        selected_menu_locales: {},
        locale: "ge",
        headerScrolled: false,
        disableScrollStatus: false,
        scrollToTopLoco: false,
        translations: {},
        siteMode: "production",
        triggerUpdate: false,
        menuStatus: {level1: 1, level2: 4, level3: 0, level4: 0},
        isBurgerMenuOpen: false,
        headerLogos: [],
        showNotification: false,
        Loco: null,
        hasScrolled: false,
        headerScrolledValue: 200,
        locoScrollY: 0,
        isMountedStepThree: false,
        isBurgerOpen: false,
        isNoTop: false,
        isHeaderOpaque: true,
        isPageTransitioningStepOne: false,
        isPageTransitioningStepTwo: false,
        isDefaultPageTransitioningStepOne: false,
        isDefaultPageTransitioningStepTwo: false,
        isMainSliderLoaded: true,
        isProjectSliderPreloaded: false,
        isDark: false,
        isClicking: false,
        cursorComponent: null,
        api_key: ''
    }),
    //
    getters: {
        getSingleUrl: (state) => (params: any, currentPage: any) => {
            if (!parseInt(get(params, "id"))) return "#";
            let singlePath = "/singleview/" + params.id + "-" + params.slug;

            if (currentPage === 1) {
                return state.selected_menu.full_url_nohash + "" + singlePath;
            }

            let menuPage: any = {};

            if (parseInt(params.singlePageRoute)) {
                menuPage = find(state.indx.menus, {
                    id: parseInt(params.singlePageRoute),
                });
            } else if (
                parseInt(
                    get(state.indx, [
                        "contentTypes",
                        params.content_type,
                        "settings",
                        "defaultSingleRoute",
                    ])
                )
            ) {
                menuPage = find(state.indx.menus, {
                    id: parseInt(
                        state.indx.contentTypes[params.content_type].settings
                            .defaultSingleRoute
                    ),
                });
            }

            let ret = "";

            if (get(menuPage, "full_url_nohash")) {
                ret = menuPage.full_url_nohash + "" + singlePath;
            } else {
                ret = state.selected_menu.full_url_nohash + "" + singlePath;
            }

            return ret;
        },

        getList: (state) => (params: any) => {
            if (!get(state, params.key)) return {};
            return JSON.parse(JSON.stringify(get(state, params.key)));
        },
        getLang(): string {
            return this.locale;
        },
        getSiteSettings(): object {
            return this.indx.siteSettings;
        },
        getCurrentContentSeo(): object {
            return this?.selected_menu?.seo;
        },
        getFromList: (state) => (params: { [key: string]: any }) => {
            if (!get(state, params.key) || !get(params, "val")) return {};
            params.field = params.field ? params.field : "id";

            for (let i in get(state, params.key)) {
                if (
                    get(state, params.key + "." + i + "." + params.field) ==
                    params.val
                )
                    return JSON.parse(
                        JSON.stringify(get(state, params.key + "." + i))
                    );
            }

            return {};
        },
    },
    actions: {
        async fetchIndx() {
            let tmp: {
                status: number;
                data: StateInterface;
            } = await fn.postData("main/indx");
            if (tmp.status != 200) return false;
            this.indx = tmp.data;

            if (!get(this.indx.locales, this.locale)) {
                this.locale = get(tmp.data, "locale");
            }
            menu_mutation(this.indx.menus, this.locale);
            // return false;
        },
        async indxTranslatable(this: any) {
            let tmp = await fn.postData("main/indxTranslatable");

            if (tmp.status != 200) return false;
            for (let [KEY, val] of Object.entries(tmp.data)) {
                this.indx[KEY] = val;
            }
        },
        async getBulkData(params: { [key: string]: any }) {
            if (!get(params, "key")) return false;

            if (size(get(this.cache, "params.key")) && !get(params, "force"))
                return false;

            let tmp = await fn.postData("main/getBulkData", params);

            if (tmp.status != 200) return false;

            this.cache[params.key] = tmp.data;
        },
        async getTranslations() {
            if (Object.keys(this.translations).length) return;
            let tmp = await fn.postData("main/getTranslations");
            if (tmp.status != 200) return false;
            this.translations = tmp.data;
        },
        async getPageContent(this: any, params: { [key: string]: any }) {
            let res = await fn.postData("main/getDataList", params);

            if (res.status != 200) {
                this.current_content_request_status = "error";
                return false;
            }

            if (!get(res, "data.componentUnicId")) {
                return false;
            }
            let i: keyof typeof
            this.current_content.secondary;
            for (i in this.current_content.secondary) {
                if (
                    get(this.current_content.secondary[i], "conf.unicId") ===
                    get(res, "data.componentUnicId")
                ) {
                    this.current_content.secondary[i].data["list"] =
                        res.data.list;
                    this.current_content.secondary[i].data["listCount"] =
                        res.data.listCount;
                    break;
                }
            }
        },

        async getCurrentContentProto(params: { [key: string]: any }) {
            if (!params || !params.id) {
                return false;
            }

            let request: any = {
                contentid: params.id,
                selectedlan: this.locale,
            };
            if (params.routeParams) {
                for (let i in params.routeParams) {
                    request[i] = params.routeParams[i];
                }
            }

            let current_content_last_request = JSON.stringify(request);

            if (
                current_content_last_request ==
                this.current_content_last_request
            )
                return false;
            this.current_content_last_request = current_content_last_request;
            this.current_content_request_status = "pending";

            let res = await fn.postData("main/getCurrentContent", request);

            if (res.status != 200) {
                this.current_content = {};
                this.current_content_request_status = "error";

                return false;
            }

            this.current_content_buffer = res.data;
            this.current_content_request_status = "done";
        },

        getComponentCursor(e: Event, componentType: string) {
            this.cursorComponent = componentType;
        },
    },
});
