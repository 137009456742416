import {mapActions, mapState} from "pinia";
import {useMenuStore} from "../store/pinia/menu/index";
import {
    set_current_menu_locale_paths,
    set_current_content_from_buffer,
} from "../store/pinia/menu/menu_helpers";

export default {
    data() {
        return {
            pageLoadStatus: "none",

            enableComponent: false,
            animateTxt: true,
            activeTab: 1,
            aboutTabHeight: 0,
            selectedMenu: {},

            defaultComponent: "Default",
            componentToLoad: "", /// template to load
            loadedComponent: "", /// template to load

            routeParams: {},
            fullPath: "",

            innerTemplates: {
                Default: "Default",
            },

            currentRouteHash: "",
        };
    },

    watch: {
        current_content_request_status: {
            immediate: true,
            handler: function () {
                if (this.current_content_request_status === "done") {
                    this.loadNewPage();
                }
            },
        },
    },
    computed: {
        ...mapState(useMenuStore, ["current_content_request_status", "indx"]),
    },
    methods: {
        ...mapActions(useMenuStore, ["getCurrentContentProto"]),
        pageRoutingSelectMenuByPath() {
            const store = useMenuStore();
            //TODO დავაკომენტარე რადგან რეატიულობას კარგავდა ამის გარეშე, თუმცა გასარკვევია რატომ
            // if (!_.get(this.indx, "menus.0.id")) return false;
            this.selectedMenu = this.getMenuFromPath(this.getRouteParams());
            this.selectedMenu.currentLayoutTemplate = this.findLayoutComponent(
                this.selectedMenu
            );
            store.selected_menu = this.selectedMenu;

            if (!this.selectedMenu) return false;
            this.getCurrentContentProto(this.selectedMenu);


            set_current_menu_locale_paths(store);
        },

        pageRoutingGetContent() {
            const store = useMenuStore();

            if (!this.updateRouteHash() && _.get(store.selected_menu, "id"))
                return false;

            this.pageLoadStatus = "started";
            this.getCurrentContentProto(store.selected_menu);
            this.pageLoadStatus = "waitContent";
        },

        loadNewPage() {
            const store = useMenuStore();

            let _this = this;
            store.smart_components_load_status = "active";

            _this.pageLoadStatus = "startChanging";
            _this.enableComponent = false;
            set_current_content_from_buffer(store);
            _this.loadedComponent = _this.componentToLoad;
            store.selected_menu = this.selectedMenu;

            setTimeout(() => {
                _this.enableComponent = true;
            }, 355);

            setTimeout(() => {
                store.smart_components_load_status = "";
            }, 700);

            this.pageLoadStatus = "pageLoaded";
        },

        getRouteParams() {
            this.routeParams = {};
            let ready = false;

            /// ეძებს pathEndKeywords -ის მიხედვით პარამეტრებს
            /// თუ იპოვის რომელიმე pathEndKeywords-დან რომელიმე საკვანძო სიტყვას, მის მნიშვნელობად ელოდება int ტიპს.
            /// მაგ.: ge/projects/alliance-centropolis/apview/block-2/floor-10/8097-apartment-105 მისამართში საკვანძო სიტყვაა apview, ხოლო მისი მნიშვნელობაა 8097.
            // მათ შორის ყველაფერი იგნორდება და წარმოადგენს უბრალოდ შემავსებელს
            for (let i in this.$route.params) {
                // if (this.pathEndKeywords.indexOf(this.$route.params[i]) >= 0) {
                if (_.includes(this.pathEndKeywords, this.$route.params[i])) {
                    ready = this.$route.params[i];
                    continue;
                }

                if (ready && parseInt(this.$route.params[i])) {
                    // this.$set(this.routeParams, ready, parseInt(this.$route.params[i]));
                    this.routeParams[ready] = parseInt(this.$route.params[i]);
                    ready = false;
                }
            }

            this.fullPath = this.getPath();

            return this.routeParams;
        },

        addBodyClass(breadcrumb) {
            let myBody = document.getElementsByTagName("body")[0];
            document.body.className = "";
            for (let i in breadcrumb) {
                myBody.classList.add(_.get(breadcrumb[i], "url"));
            }
        },

        /// find layout template depend on selected menu
        findLayoutComponent(selectedMenu) {
            let template = this.defaultComponent;

            if (_.get(selectedMenu, "secondary_template")) {
                template = selectedMenu.secondary_template;
            }


            /// single template load patterns
            if (parseInt(_.get(this.routeParams, "singleview"))) {
                //// load single template for content purposes
                template = this.getSingleViewTemplate(template); //_.get( this.innerTemplates, template);

            } else if (parseInt(_.get(this.routeParams, "apview"))) {
                //// load single template for developmentmodule (single flat view) purposes
                template = this.getSingleViewTemplate(template); //_.get( this.innerTemplates, template);
            }

            this.componentToLoad = template;
            return template;
        },

        getSingleViewTemplate(template) {
            const store = useMenuStore();

            let singleViewTemplate = _.get(store.indx, [
                "contentTypes",
                _.get(
                    store.current_content,
                    "primary.data.list.0.content_type"
                ),
                "settings",
                "single_template",
            ]);

            if (!singleViewTemplate && _.get(this.innerTemplates, template))
                singleViewTemplate = _.get(this.innerTemplates, template);

            if (this.$options.components[singleViewTemplate])
                template = singleViewTemplate;

            return template;
        },

        /** check if route updated, to prevent dublicated runs */
        updateRouteHash() {
            if (
                this.$route.name + "-" + JSON.stringify(this.$route.params) ===
                this.currentRouteHash
            )
                return false;
            this.currentRouteHash =
                this.$route.name + "-" + JSON.stringify(this.$route.params);
            return true;
        },
    },
};
